import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'react-bootstrap';
import MostBotsChart from './MostBotsChart';
import FalconCardHeader from 'components/common/FalconCardHeader';
import { rgbaColor } from 'helpers/utils';
import { Link } from 'react-router-dom';
import { MostBots as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { coinNameMap } from 'staticData/common';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import LoadingSpinner from 'components/utilities/LoadingSpinner';
import { toast } from 'react-toastify';
import { capitalize } from 'helpers/utils';
import SimpleBarReact from 'simplebar-react';
import { coinsData } from 'services/coins/common';

const colorMap = {
  gold: rgbaColor('#ffd700'),
  silver: rgbaColor('#bec7c7'),
  neutral: rgbaColor('#17B169', 1.0),
  invested: rgbaColor('#0074d9', 0.7),
  notInvested: rgbaColor('#0074d9', 0.4)
};

const MostBots = ({
  dataReady,
  walletCoinMap,
  loaded,
  setLoaded,
  parentLoaded,
  exchange = 'binance'
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const [dataList, setDataList] = useState([]);
  const [generalWallet, setGeneralWallet] = useState({});
  const [walletChartData, setWalletChartData] = useState([]);
  // const [loaded, setLoaded] = useState(false);
  const [realResponse, setRealResponse] = useState({});

  /**
   * Return all exchanges overview, bur use only Binance actually (change in future)
   * @param {*} allocatedAmount
   * @returns
   */
  const getRealWallet = async allocatedAmount => {
    let exchangeInfos;
    // exclude to load again the backend call
    if (!loaded)
      exchangeInfos = await endpointInterface(
        lang,
        'backend',
        'getUserExchangesOverview',
        'get',
        true,
        {},
        true
      );
    // use the correct response (the state if is loaded)
    if (loaded) exchangeInfos = realResponse;
    else setRealResponse(exchangeInfos);
    if (!exchangeInfos.validResponse) {
      toast.error(exchangeInfos.responseMessage, {
        closeButton: false
      });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
      return {};
    } else {
      // Get only Binance, TODO: get wallet name from context 'visualType'
      let realWallet = exchangeInfos.data[capitalize(exchange)];
      let availableAmount = realWallet.amount - allocatedAmount;
      let allocatedPerc = (allocatedAmount * 100) / realWallet.amount;
      let availablePerc = (availableAmount * 100) / realWallet.amount;
      return {
        allocated: allocatedAmount ? allocatedAmount.toFixed(2) : 0,
        allocatedPerc: availableAmount < 0 ? 100 : allocatedPerc.toFixed(2),
        available: availableAmount < 0 ? 0 : availableAmount.toFixed(2),
        availablePerc: availableAmount < 0 ? 0 : availablePerc.toFixed(2)
      };
    }
  };

  /**
   * Order Coin and get an Array from Object
   * @returns Array of coins
   */
  const orderData = () => {
    // Exclude the total wallet (virtual or real)
    const filteredArray = Object.entries(walletCoinMap[exchange]).filter(
      ([key]) => key !== 'total'
    );
    // Sort array
    const sortedArray = filteredArray.sort((a, b) => b[1] - a[1]);
    const orderedChartData = sortedArray.map(([key, value]) => ({
      key,
      value,
      name: key,
      perc:
        walletCoinMap[exchange]['total'] && value
          ? (value / walletCoinMap[exchange]['total']) * 100
          : 0,
      itemStyle: { color: coinsData[key].color }
    }));
    return orderedChartData;
  };

  const manageWalletData = walletData => {
    let tmpManagedWallet = [];
    // Add Invested Info
    tmpManagedWallet.push({
      name: textLang['Invested'][lang],
      value: walletData.allocated,
      perc: walletData.allocatedPerc,
      itemStyle: { color: colorMap.invested }
    });
    // Add NOT Invested Info
    tmpManagedWallet.push({
      name: textLang['Not Invested'][lang],
      value: walletData.available,
      perc: walletData.availablePerc,
      itemStyle: { color: colorMap.notInvested }
    });
    return tmpManagedWallet;
  };

  const initChartData = async () => {
    if (Object.keys(walletCoinMap).includes(exchange)) {
      // Get DataList for coin List under Cake Chart
      let chartCoinsData = orderData();
      setDataList(chartCoinsData);
      // Get data for Cacke Chart
      let managedWallet = walletCoinMap[exchange].total;
      let tmpGeneralWallet;
      tmpGeneralWallet = await getRealWallet(managedWallet);
      setGeneralWallet(tmpGeneralWallet);
      setWalletChartData(manageWalletData(tmpGeneralWallet));
      if (!loaded) setLoaded(true);
    }
  };

  useEffect(() => {
    try {
      if (parentLoaded && dataReady && !loaded) {
        initChartData();
      }
    } catch (error) {
      console.error(error.message);
    }
  }, [dataReady, walletCoinMap, parentLoaded]);

  return (
    <Card className={'h-100 virtualCard'}>
      <FalconCardHeader
        title={textLang.title[lang]}
        titleTag="h6"
        className="border-200 border-bottom py"
      />
      {loaded && parentLoaded ? (
        <Card.Body as={Row}>
          <Col
            className={`mb-xl-1 d-none d-sm-block col-12 col-sm-12 col-md-5 ${
              dataList.length === 0 && walletChartData.length === 0
                ? ''
                : 'col-xl-12'
            }`}
          >
            {dataReady && (
              <MostBotsChart
                coinChartData={dataList ? dataList : null}
                totalCoins={dataList ? dataList.length : null}
                holdChartData={walletChartData ? walletChartData : null}
              />
            )}
          </Col>
          <Col
            className={`col-12 col-sm-12 col-md-7 ${
              dataList.length === 0 && walletChartData.length === 0
                ? ''
                : 'col-xl-12'
            }`}
          >
            <div
              style={{
                borderRadius: '8px',
                border: 'solid rgba(216, 226, 239, 0.25)',
                borderWidth: '0.01em',
                padding: '8px',
                marginTop: '6px'
              }}
            >
              <SimpleBarReact
                style={{
                  maxHeight: `250px`,
                  width: '100%'
                }}
              >
                {dataList.length === 0 ? (
                  <p
                    className="text-700 text-center"
                    style={{
                      fontSize: '10pt'
                    }}
                  >
                    {textLang.noBotDescription[lang]}
                  </p>
                ) : (
                  <>
                    <h6 style={{ textAlign: 'start', marginBottom: '12px' }}>
                      {textLang.wallet[lang]}
                    </h6>
                    {Object.keys(generalWallet).length > 0 && (
                      <>
                        <p
                          className="text-700"
                          style={{
                            fontSize: '10pt'
                          }}
                        >
                          <span
                            style={{
                              height: '10px',
                              width: '10px',
                              backgroundColor: colorMap.invested,
                              borderRadius: '50%',
                              display: 'inline-block',
                              marginRight: '8px'
                            }}
                          ></span>
                          {textLang['Invested'][lang]}
                          <span className="text-900 ps-2 fw-medium fs--2">
                            ${generalWallet.allocated}
                            {'  (' + generalWallet.allocatedPerc + '%)'}
                          </span>
                        </p>
                        <p
                          className="text-700"
                          style={{
                            fontSize: '10pt'
                          }}
                        >
                          <span
                            style={{
                              height: '10px',
                              width: '10px',
                              backgroundColor: colorMap.notInvested,
                              borderRadius: '50%',
                              display: 'inline-block',
                              marginRight: '8px'
                            }}
                          ></span>
                          {textLang['Not Invested'][lang]}
                          <span className="text-900 ps-2 fw-medium fs--2">
                            ${generalWallet.available}
                            {'  (' + generalWallet.availablePerc + '%)'}
                          </span>
                        </p>
                      </>
                    )}
                    <h6
                      style={{
                        textAlign: 'start',
                        marginBottom: '12px',
                        marginTop: '6px'
                      }}
                    >
                      {textLang.assets[lang]}
                    </h6>

                    {(!dataList || dataList.length == 0) && (
                      <p
                        className="text-700"
                        style={{
                          fontSize: '10pt'
                        }}
                      >
                        <span
                          style={{
                            height: '10px',
                            width: '10px',
                            borderRadius: '50%',
                            display: 'inline-block',
                            marginRight: '8px'
                          }}
                        ></span>
                        {textLang.noAssets[lang]}
                        <Link className="text-decoration-none" to="/invest">
                          {textLang.start[lang]}
                        </Link>
                      </p>
                    )}
                    {dataList.map(
                      (datum, index) =>
                        datum.value > 0 && (
                          <p
                            key={index}
                            className="text-700"
                            style={{
                              fontSize: '10pt'
                            }}
                          >
                            <span
                              style={{
                                height: '10px',
                                width: '10px',
                                backgroundColor:
                                  datum.itemStyle.color || 'orange',
                                borderRadius: '50%',
                                display: 'inline-block',
                                marginRight: '8px'
                              }}
                            ></span>
                            {coinNameMap[datum.key] + ` (${datum.key})`}
                            <span className="text-900 ps-2 fw-medium fs--2">
                              ${datum.value.toFixed(2)}
                              {'  ('}
                              {datum.perc.toFixed(2)}%{')'}
                            </span>
                          </p>
                        )
                    )}
                  </>
                )}
              </SimpleBarReact>
            </div>
          </Col>
        </Card.Body>
      ) : (
        <div style={{ paddingTop: '20%' }}>
          <LoadingSpinner subText=""></LoadingSpinner>
        </div>
      )}
    </Card>
  );
};

MostBots.propTypes = {
  walletCoinMap: PropTypes.object,
  dataReady: PropTypes.bool.isRequired,
  loaded: PropTypes.bool,
  setLoaded: PropTypes.func,
  parentLoaded: PropTypes.bool,
  exchange: PropTypes.string
};

export default MostBots;
