import React, { useState, useEffect, useContext } from 'react';
import {
  Row,
  Card,
  Col,
  Tooltip,
  OverlayTrigger,
  Table
} from 'react-bootstrap';
import SimpleBarReact from 'simplebar-react';
import IconButton from 'components/common/IconButton';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';
import { Rewards as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import SoftBadge from 'components/common/SoftBadge';
import { ShareReferralOnSocial as shareTextLang } from 'staticData/languages';
import ShareOnSocial from './modals/shareOnSocial';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import { getColor } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';
import 'assets/scss/custom/rewards.scss';
import { ShareOnSocialModal as textLangShare } from 'staticData/languages';
import { trackEvent } from 'services/externals/mixpanel';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { GaugeChart } from 'echarts/charts';
import {
  EmailIcon,
  FacebookIcon,
  TelegramIcon,
  WhatsappIcon,
  EmailShareButton,
  FacebookShareButton,
  TelegramShareButton,
  WhatsappShareButton
} from 'react-share';
import { demoReferralFriends, demoTrackingID } from 'staticData/demoData';
import classNames from 'classnames';
import InfoPopover from 'components/utilities/InfoPopover';
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  GaugeChart,
  CanvasRenderer,
  LegendComponent
]);

const handleTrackEvent = type => {
  trackEvent('Share', {
    type: type
  });
};

const index = () => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  const referral = userInfos.my_referral;
  var defaultTooltipText = textLang.copy[lang];
  const [tooltipText, setTooltipText] = useState(defaultTooltipText);
  const [shareOnSocialModalShow, setShareOnSocialModalShow] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [referralUsers, setReferralUsers] = useState([]);

  const startNewReferral = new Date('2024-10-09T00:00:00');

  const getDate = dateStr => {
    let date = new Date(dateStr);

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    return `${day < 10 ? '0' : ''}${day}-${
      month < 10 ? '0' : ''
    }${month}-${year}`;
  };

  const copyReferral = async () => {
    handleTrackEvent('manual copy');
    navigator.clipboard.writeText(referral);
    setTooltipText(textLang.copied[lang]);
    setTimeout(() => {
      setTooltipText(defaultTooltipText);
    }, 1500);
  };

  const getReferralStatus = async () => {
    const resp = await endpointInterfaceV2({
      internalEndpoint: 'referralStatus',
      httpMethod: 'get',
      usingCredentials: true
    });
    if (resp.validResponse) return resp.data;
    return { referrals: [], redeemed_items: [], available_redeems: {} };
  };

  const processData = data => {
    let referrals = data.referrals.slice();
    // Sort the array based on status and created_at
    referrals.sort((a, b) => {
      if (a.status === 'PAID' && b.status !== 'PAID') return -1;
      if (b.status === 'PAID' && a.status !== 'PAID') return 1;
      if (a.status === 'TRIALING' && b.status !== 'TRIALING') return -1;
      if (b.status === 'TRIALING' && a.status !== 'TRIALING') return 1;
      if (a.status === 'REGISTERED' && b.status !== 'REGISTERED') return -1;
      if (b.status === 'REGISTERED' && a.status !== 'REGISTERED') return 1;

      // If status is the same, sort by created_at
      let dateA = new Date(a.created_at);
      let dateB = new Date(b.created_at);
      return dateA - dateB;
    });

    if (userInfos.tracking_id === demoTrackingID)
      setReferralUsers(demoReferralFriends);
    else setReferralUsers(referrals);
  };

  const getOptions = data => ({
    series: [
      {
        type: 'gauge',
        startAngle: 90,
        endAngle: -270,
        radius: '80%',
        pointer: {
          show: false
        },
        progress: {
          show: true,
          overlap: false,
          roundCap: true,
          clip: false,
          itemStyle: {
            color: getColor('primary')
          }
        },
        axisLine: {
          lineStyle: {
            width: 20,
            color: [[1, getColor('300')]],
            opacity: 0.5
          }
        },
        splitLine: {
          show: false
        },
        axisTick: {
          show: false
        },
        axisLabel: {
          show: false
        },
        data: [
          {
            value: data,
            detail: {
              offsetCenter: ['7%', '4%']
            }
          }
        ],
        detail: {
          show: false
        }
      }
    ]
  });

  useEffect(async () => {
    processData(await getReferralStatus());
    if (!loaded) {
      setLoaded(true);
    }
  }, []);

  const logoSize = 54;
  const shareBody = (
    <>
      <h6 className="text-center font-weight-bold">
        {textLangShare.shareOnSocial[lang]}
      </h6>
      <Row className="mt-4 mb-4 d-flex flex-row align-items-center justify-content-center">
        <div
          style={{ width: 'fit-content' }}
          className="p-2"
          onClick={() => handleTrackEvent('whatsapp')}
        >
          <WhatsappShareButton
            url={shareTextLang.shareUrl + referral}
            title={shareTextLang.shareText[lang]}
          >
            <WhatsappIcon size={logoSize} round />
          </WhatsappShareButton>
        </div>
        <div
          style={{ width: 'fit-content' }}
          className="p-2"
          onClick={() => handleTrackEvent('telegram')}
        >
          <TelegramShareButton
            url={shareTextLang.shareUrl + referral}
            title={shareTextLang.shareTitle[lang]}
          >
            <TelegramIcon size={logoSize} round />
          </TelegramShareButton>
        </div>
        <div
          style={{ width: 'fit-content' }}
          className="p-2"
          onClick={() => handleTrackEvent('facebook')}
        >
          <FacebookShareButton
            url={shareTextLang.shareUrl + referral}
            quote={shareTextLang.shareText[lang]}
            hashtag="#hodlie"
          >
            <FacebookIcon size={logoSize} round />
          </FacebookShareButton>
        </div>
        <div
          style={{ width: 'fit-content' }}
          className="p-2"
          onClick={() => handleTrackEvent('email')}
        >
          <EmailShareButton
            url={shareTextLang.shareUrl + referral}
            subject={shareTextLang.shareTitle[lang]}
            body={shareTextLang.shareText[lang]}
            separator={': '}
            hashtag="#hodlie"
          >
            <EmailIcon size={logoSize} round />
          </EmailShareButton>
        </div>
      </Row>
    </>
  );

  return (
    <>
      <ShareOnSocial
        modalShow={shareOnSocialModalShow}
        setModalShow={setShareOnSocialModalShow}
        body={shareBody}
      ></ShareOnSocial>
      <Row xs={12} className="pb-3 mb-0">
        <Col lg={12} className="pb-0 mb-0">
          <Card className="overflow-hidden">
            <Card.Header>
              <h2
                className="text-800 mb-0"
                dangerouslySetInnerHTML={{
                  __html: textLang.affiliationTitle[lang]
                }}
              ></h2>
            </Card.Header>
            <Card.Body>
              <Row>
                <Col>
                  <p
                    className="fs-1"
                    dangerouslySetInnerHTML={{
                      __html: textLang.firstCardText[lang]
                    }}
                  />
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={5} className="mb-3">
          <Card className="overflow-hidden h-100">
            <Card.Body
              className="justify-content-center p-2 pe-0 align-content-center"
              style={{ flexWrap: 'wrap' }}
            >
              <Row className="justify-content-center pb-sm-5">
                <Col className="d-grid justify-content-center">
                  <Flex className="p-0">
                    <span className="d-flex text-center p-0 m-0">
                      <h4 className="text-900" style={{ margin: '3px' }}>
                        {textLang.inviteAFriend[lang]}
                      </h4>
                    </span>
                  </Flex>
                  <Flex className="mb-2 mt-1" justifyContent="center">
                    <SoftBadge
                      className=" d-flex align-items-center justify-content-center"
                      bg="info"
                    >
                      <p className="m-0 p-0" style={{ fontSize: '20px' }}>
                        {referral}
                      </p>
                    </SoftBadge>
                    <OverlayTrigger
                      key="copy"
                      placement="top"
                      overlay={
                        <Tooltip id="copyReferral">{tooltipText}</Tooltip>
                      }
                    >
                      <div style={{ marginLeft: '5px', width: 'fit-content' }}>
                        <IconButton
                          style={{ padding: '0 0.35rem' }}
                          variant="outline-info"
                          icon="copy"
                          onClick={copyReferral}
                        ></IconButton>
                      </div>
                    </OverlayTrigger>
                    <OverlayTrigger
                      key="share"
                      placement="top"
                      overlay={
                        <Tooltip id="share">
                          {textLang.shareWithFriend[lang]}
                        </Tooltip>
                      }
                    >
                      <div
                        style={{ marginLeft: '5px', width: 'fit-content' }}
                        className="d-sm-none"
                      >
                        <IconButton
                          style={{ padding: '0 0.35rem' }}
                          variant="outline-info"
                          icon="share-alt"
                          onClick={() => setShareOnSocialModalShow(true)}
                        ></IconButton>
                      </div>
                    </OverlayTrigger>
                  </Flex>
                </Col>
              </Row>
              <Row className="d-none d-sm-block">
                <Col className="">{shareBody}</Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={12} md={7} className="mb-3">
          <Card className="overflow-hidden">
            <Card.Body>
              <Row className="pb-3 mb-0">
                <Col
                  dangerouslySetInnerHTML={{
                    __html: textLang.lastCardTextHtml[lang]
                  }}
                ></Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card className="overflow-hidden mb-3">
        <Card.Header className="mb-0">
          <h5 className="text-800">{textLang.friends[lang]} </h5>
        </Card.Header>
        <Row className=" mb-0">
          <Col lg={7} className="pb-0 mb-3 mb-sm-0">
            <SimpleBarReact
              style={{
                minHeight: referralUsers.length > 0 ? '20rem' : '3rem',
                maxHeight: '20rem',
                width: '100%'
              }}
            >
              <Table responsive>
                {referralUsers.length > 0 ? (
                  <>
                    <thead>
                      <tr>
                        <th className="text-dark" scope="col">
                          Username
                        </th>
                        <th className="text-dark" scope="col">
                          {textLang.date[lang]}
                        </th>
                        <th className="text-dark" scope="col">
                          {textLang.sub[lang]}
                        </th>
                        <th className="text-dark" scope="col">
                          Bonus
                        </th>
                      </tr>
                    </thead>
                    <tbody style={{ whiteSpace: 'nowrap' }}>
                      {loaded &&
                        referralUsers.map(
                          (user, index) =>
                            user.status === 'PAID' && (
                              <tr key={index}>
                                <td>
                                  <FontAwesomeIcon
                                    icon="user"
                                    className="me-2"
                                  />
                                  {user.username}
                                </td>
                                <td>{getDate(user.created_at)}</td>
                                <td>
                                  <FontAwesomeIcon
                                    icon="check"
                                    color={getColor('success')}
                                    className="me-2"
                                  />
                                  {user.months === 1
                                    ? textLang.monthly[lang]
                                    : textLang.annual[lang]}
                                </td>
                                <td
                                  className={classNames('', {
                                    'text-success':
                                      user.months === 12 &&
                                      user.referral_bonus_paid
                                  })}
                                >
                                  {startNewReferral >
                                  new Date(user.created_at) ? (
                                    <Flex alignItems="end">
                                      <span>{textLang.notValid[lang]}</span>
                                      <InfoPopover
                                        Text={textLang.notValidStart[lang]}
                                      />
                                    </Flex>
                                  ) : user.months === 1 ? (
                                    <Flex alignItems="end">
                                      <span>{textLang.notValid[lang]}</span>
                                      <InfoPopover
                                        Text={textLang.notValidInfo[lang]}
                                      />
                                    </Flex>
                                  ) : user.referral_bonus_paid ? (
                                    textLang.processed[lang]
                                  ) : (
                                    textLang.processing[lang]
                                  )}
                                </td>
                              </tr>
                            )
                        )}
                      {loaded &&
                        referralUsers.map(
                          (user, index) =>
                            user.status === 'TRIALING' && (
                              <tr key={index}>
                                <td>
                                  <FontAwesomeIcon
                                    icon="user"
                                    className="me-2"
                                  />
                                  {user.username}
                                </td>
                                <td>{getDate(user.created_at)}</td>
                                <td>
                                  <FontAwesomeIcon
                                    icon={['far', 'hourglass']}
                                    color={getColor('info')}
                                    className="me-2"
                                  />
                                  {user.months === 1
                                    ? textLang.monthly[lang]
                                    : textLang.annual[lang]}
                                </td>
                                <td
                                  className={classNames('', {
                                    'text-success':
                                      user.months === 12 &&
                                      user.referral_bonus_paid
                                  })}
                                >
                                  {startNewReferral >
                                  new Date(user.created_at) ? (
                                    <Flex alignItems="end">
                                      <span>{textLang.notValid[lang]}</span>
                                      <InfoPopover
                                        Text={textLang.notValidStart[lang]}
                                      />
                                    </Flex>
                                  ) : user.months === 1 ? (
                                    <Flex alignItems="end">
                                      <span>{textLang.notValid[lang]}</span>
                                      <InfoPopover
                                        Text={textLang.notValidInfo[lang]}
                                      />
                                    </Flex>
                                  ) : user.referral_bonus_paid ? (
                                    textLang.processed[lang]
                                  ) : (
                                    textLang.processing[lang]
                                  )}
                                </td>
                              </tr>
                            )
                        )}
                      {loaded &&
                        referralUsers.map(
                          (user, index) =>
                            user.status === 'REGISTERED' && (
                              <tr key={index}>
                                <td>
                                  <FontAwesomeIcon
                                    icon="user"
                                    className="me-2"
                                  />
                                  {user.username}
                                </td>
                                <td>{getDate(user.created_at)}</td>
                                <td>
                                  <FontAwesomeIcon
                                    icon="times"
                                    color={getColor('danger')}
                                  />
                                </td>
                                <td className="">{textLang.notValid[lang]}</td>
                              </tr>
                            )
                        )}
                    </tbody>
                  </>
                ) : (
                  loaded && (
                    <Card.Body className="mt-5">
                      {textLang.noFriends[lang]}
                    </Card.Body>
                  )
                )}
              </Table>
            </SimpleBarReact>
          </Col>
          <Col sm={5} className="">
            <div className="position-relative py-3">
              <ReactEChartsCore
                echarts={echarts}
                option={getOptions(
                  referralUsers.length
                    ? (referralUsers.filter(
                        entry => entry.status === 'PAID' && entry.months === 12
                      ).length *
                        100) /
                        referralUsers.length
                    : 0
                )}
                style={{ height: '20rem' }}
              />
              <div className="position-absolute top-50 start-50 translate-middle text-center">
                <p className="fs--1 mb-0 text-400 font-sans-serif fw-medium">
                  <b>{textLang.chartTitle[lang]}</b>
                </p>
                <p className="fs-6 mb-0 font-sans-serif fw-medium mt-n2 pt-2">
                  {
                    referralUsers.filter(
                      entry => entry.status === 'PAID' && entry.months === 12
                    ).length
                  }
                  {referralUsers.length > 0 && (
                    <small className="fs-1">/{referralUsers.length}</small>
                  )}
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Card>
      <Card className="overflow-hidden">
        <Card.Header>
          <h2 className="text-800 mb-3">{textLang.lastParTitle[lang]}</h2>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col
              dangerouslySetInnerHTML={{
                __html: textLang.terms[lang]
              }}
            />
          </Row>
        </Card.Body>
      </Card>
      <Card className="overflow-hidden mt-3">
        <Card.Header>
          <h2 className="text-800 mb-3">FAQ</h2>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col
              dangerouslySetInnerHTML={{
                __html: textLang.faqList[lang]
              }}
            />
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

index.propTypes = {
  referral: PropTypes.string
};

export default index;
