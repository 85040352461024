import React from 'react';
import PropTypes from 'prop-types';
import Flex from 'components/common/Flex';

const BlurChildren = ({ useBlur = false, text = '', children }) => {
  return (
    <div style={{ position: 'relative' }}>
      {useBlur && (
        <Flex
          justifyContent="center"
          alignItems="center"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            borderRadius: '.375rem',
            width: '100%',
            height: '100%',
            backdropFilter: 'blur(5px)', // blur effect
            WebkitBackdropFilter: 'blur(10px)', // Safari and Chrome
            zIndex: 3
          }}
        >
          {text}
        </Flex>
      )}
      <div style={{ position: 'relative', zIndex: 2 }}>{children}</div>
    </div>
  );
};

BlurChildren.propTypes = {
  children: PropTypes.node.isRequired,
  text: PropTypes.string,
  useBlur: PropTypes.bool
};

export default BlurChildren;
