import React, { useState, useEffect, useContext } from 'react';
import ConfirmMailLayout from './ConfirmMailLayout';
import ConfirmMailForm from './ConfirmMailForm';
import { toast } from 'react-toastify';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import AppContext from 'context/Context';

const ConfirmMail = () => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const queryParameters = new URLSearchParams(window.location.search);
  const uniqueCode = queryParameters.get('uc');
  const [loaded, setLoaded] = useState(false);
  const [wrongUC, setWrongUC] = useState();

  const getUCInfo = async () => {
    let form = new FormData();
    form.append('uq', uniqueCode);
    let UCInfoResponse = await endpointInterface(
      lang,
      'backend',
      'confirmMail',
      'post',
      true,
      form
    );
    if (UCInfoResponse.validResponse) setWrongUC(false);
    else {
      setWrongUC(true);
      var check = true;
      if (UCInfoResponse.errorID === 'noUser') {
        check = false;
      }
      if (check) {
        toast.error(UCInfoResponse.responseMessage, { closeButton: false });
        setTimeout(() => {
          toast.dismiss();
        }, 5000);
      }
    }
  };

  useEffect(async () => {
    if (!loaded) {
      try {
        await getUCInfo();
        setLoaded(true);
      } catch (error) {
        console.error(error.message);
      }
    }
  }, []);

  return (
    <ConfirmMailLayout>
      <ConfirmMailForm hasLabel wrongUC={wrongUC} />
    </ConfirmMailLayout>
  );
};

export default ConfirmMail;
