import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Card, Form, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import { WizardLayout as textLang } from 'staticData/languages';

const WizardLayout = ({ step, ...rest }) => {
  const { className } = rest;
  const {
    config: { lang }
  } = useContext(AppContext);
  const navItems = [
    {
      icon: 'envelope',
      label: 'Mail'
    },
    {
      icon: 'user',
      label: 'KYC'
    },
    {
      icon: 'wallet',
      label: 'Wallet'
    },
    {
      icon: 'check',
      label: textLang.done[lang]
    }
  ];

  return (
    <>
      <Card
        as={Form}
        noValidate
        className={`theme-wizard ${className}`}
        style={{
          backgroundColor: 'var(--falcon-body-bg)',
          width: '-webkit-fill-available',
          boxShadow: 'unset'
        }}
      >
        <Nav className="justify-content-center">
          {navItems.map((item, index) => (
            <NavItem
              key={item.label}
              index={index}
              step={step}
              icon={item.icon}
              label={item.label}
            />
          ))}
        </Nav>
      </Card>
    </>
  );
};

const NavItem = ({ index, step, icon, label }) => {
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: index < 4 ? step > index : step > 3,
          active: step === index
        })}
        style={{ cursor: 'default' }}
      >
        <span className="nav-item-circle-parent">
          <span className="nav-item-circle">
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

WizardLayout.propTypes = {
  step: PropTypes.number
};

NavItem.propTypes = {
  step: PropTypes.number,
  index: PropTypes.number,
  icon: PropTypes.string,
  label: PropTypes.string
};

export default WizardLayout;
