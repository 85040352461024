import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Col, Row, Modal, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import Flex from 'components/common/Flex';
import AppContext from 'context/Context';
import { ConfirmMailModal as textLang } from 'staticData/languages';
import Wizard from 'pages/common/wizard/Wizard';
import { useHistory } from 'react-router-dom';
import sendMail from 'assets/img/animated-icons/sendMail.json';
import Lottie from 'lottie-react';
import { saveToLocalStorage, getFromLocalStorage } from 'staticData/common';

const ConfirmMail = ({
  ConfirmMailShow,
  setConfirmMailShow,
  step,
  withHeader = true,
  userCanClose = true
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const lastSendverificationMail = getFromLocalStorage('registrationMailSend')
    ? new Date(getFromLocalStorage('registrationMailSend'))
    : null;
  const timerMinutes = 1;
  const now = new Date().getTime();
  const isInTimerMinutes = lastSendverificationMail
    ? now - lastSendverificationMail < timerMinutes * 60 * 1000
    : false;
  const [disableButton, setDisableButton] = useState(false);
  const [showMessage, setShowMessage] = useState(isInTimerMinutes);
  const [showSpinner, setShowSpinner] = useState(false);
  const routerHistory = useHistory();

  const resendMail = async () => {
    setDisableButton(true);
    setShowSpinner(true);

    // Generate Recaptcha Token & Resend Mail
    window.grecaptcha.enterprise.ready(async () => {
      const recaptcha_token = await window.grecaptcha.enterprise.execute(
        '6LcE9igoAAAAAJ2plIdhi4bvhKr3hn95kyNmxTqc',
        { action: 'resend_confirm' }
      );
      let form = new FormData();
      form.append('recaptcha_token', recaptcha_token);

      let resendMailResponse = await endpointInterface(
        lang,
        'backend',
        'resendMail',
        'post',
        true,
        form
      );

      setShowSpinner(false);
      if (resendMailResponse.validResponse) {
        saveToLocalStorage('registrationMailSend', new Date().getTime());
        setShowMessage(true);
      } else {
        toast.info(resendMailResponse.responseMessage, {
          closeButton: false
        });
        setTimeout(() => {
          toast.dismiss();
        }, 5000);
      }
    });
  };

  return (
    <Modal
      show={ConfirmMailShow}
      onHide={() =>
        userCanClose
          ? setConfirmMailShow(false)
          : location.pathname === '/account'
          ? window.location.reload()
          : routerHistory.push('/')
      }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        {withHeader && <Wizard step={step} />}
      </Modal.Header>
      {showMessage ? (
        <Modal.Body className="text-center">
          <Row className="justify-content-center">
            <Col xs={10}>
              <h5>{textLang.msg[lang]}</h5>
              <Lottie
                animationData={sendMail}
                loop={true}
                style={{ height: '300px' }}
              />
            </Col>
          </Row>
        </Modal.Body>
      ) : (
        <Modal.Body className="text-center">
          <Row className="justify-content-center">
            <h5>{textLang.text[lang]}</h5>
            <Col xs={10}>
              <h6>{textLang.text1[lang]}</h6>
            </Col>
          </Row>
          <Row className="justify-content-center py-3">
            <Col xs={8} md={6}>
              <Button
                className="w-100"
                disabled={disableButton}
                onClick={() => resendMail()}
              >
                <Flex className="justify-content-center">
                  {showSpinner && (
                    <Spinner
                      animation="border"
                      className="me-2 mt-1"
                      style={{ width: '1rem', height: '1rem' }}
                    ></Spinner>
                  )}
                  {textLang.button[lang]}
                </Flex>
              </Button>
            </Col>
          </Row>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Row>
          <Col as={Flex} className="justify-content-end">
            <small>
              {textLang.smallMsg[lang]}
              <a href="mailto:support@hodlie.net">support@hodlie.net</a>
            </small>
          </Col>
        </Row>
      </Modal.Footer>
    </Modal>
  );
};

ConfirmMail.propTypes = {
  ConfirmMailShow: PropTypes.bool.isRequired,
  setConfirmMailShow: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  withHeader: PropTypes.bool.isRequired,
  userCanClose: PropTypes.bool.isRequired
};

export default ConfirmMail;
