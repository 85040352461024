import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import {
  Col,
  Row,
  Modal,
  InputGroup,
  FormControl,
  Form,
  Button
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import Flex from 'components/common/Flex';
import AppContext from 'context/Context';
import { DoKYC as textLang } from 'staticData/languages';
import Wizard from 'pages/common/wizard/Wizard';
import LoadingSpinner from 'components/utilities/LoadingSpinner';
import PlacesAutocomplete from 'react-places-autocomplete';
import { trackEvent } from 'services/externals/mixpanel';
import { Link } from 'react-router-dom';
import { breakpoints } from 'helpers/utils';
import 'onfido-sdk-ui/split/css';

const DoKYC = ({
  doKYCShow,
  setDoKYCShow,
  step,
  setKycStatus = () => null,
  setAddKeysShow = () => null,
  setStep
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  var onfido = {};
  const [disableButton, setDisableButton] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    surname: '',
    tax_code: '',
    address: '',
    place_id: '',
    mobile: ''
  });
  const [acceptedTos, setAcceptedTos] = useState(false);
  const [canStartTrial, setCanStartTrial] = useState(false);
  const [processingKYC, setProcessingKYC] = useState(false);
  const [onError, setOnError] = useState(false);
  const [stopProcessing, setStopProcessing] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [hasBinance, setHasBinance] = useState(null);
  const [invalidArray, setInvalidArray] = useState([]);
  const searchOptions = {
    types: ['address']
  };
  var intervalGetKYC;
  var KYCErrorCount = 0;
  var KYCComplete = false;

  const handleFieldChange = e => {
    if (invalidArray.includes(e.target.name)) {
      const localInvalidArray = invalidArray.filter(
        item => item !== e.target.name
      );
      setInvalidArray(localInvalidArray);
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const getVirtualTrial = async () => {
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'virtual_trial',
      httpMethod: 'get',
      usingCredentials: true,
      showError: false
    });
    var setVirtual = false;
    if (resp.validResponse) {
      if (resp.data.can_start_trial) {
        setVirtual = true;
        setCanStartTrial(setVirtual);
      }
    }
    return setVirtual;
  };

  const getKYC = async () => {
    setDisableButton(true);
    let kycResponse = await endpointInterface(
      lang,
      'backend',
      'getKYC',
      'get',
      true
    );
    if (!kycResponse.validResponse) {
      toast.error(kycResponse.responseMessage, {
        closeButton: false
      });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
    } else {
      if (kycResponse.data.kyc_status === 'todo') {
        if (KYCErrorCount < 10) {
          KYCErrorCount += 1;
        } else {
          clearInterval(intervalGetKYC);
          setStopProcessing(true);
          // window.location.reload();
        }
      } else {
        clearInterval(intervalGetKYC);
        const showVirtual = await getVirtualTrial();
        setProcessingKYC(false);
        if (!showVirtual) {
          setStep(step + 1);
          setDoKYCShow(false);
          setAddKeysShow(true);
          setKycStatus('approved');
        }
      }
    }
  };

  const handleHideModal = show => {
    if (canStartTrial) {
      setKycStatus('approved');
      setStep(step + 1);
      setAddKeysShow(true);
    }
    setDoKYCShow(show);
  };

  const handleGetKYC = async () => {
    setDoKYCShow(true);
    setProcessingKYC(true);
    intervalGetKYC = setInterval(async () => {
      await getKYC();
    }, 3000);
    await getKYC();
  };

  const handlePlaceChange = address => {
    setFormData({ ...formData, address });
    setShowSuggestions(true);
  };

  const handleSelect = value => {
    const localInvalidArray = invalidArray.filter(item => item !== 'place');
    setInvalidArray(localInvalidArray);
    setFormData({
      ...formData,
      address: value.description,
      place_id: value.placeId
    });
    setShowSuggestions(false);
  };

  const handleAcceptTOS = () => {
    if (invalidArray.includes('acceptedTos')) {
      const localInvalidArray = invalidArray.filter(
        item => item !== 'acceptedTos'
      );
      setInvalidArray(localInvalidArray);
    }
    setAcceptedTos(!acceptedTos);
  };

  const handleCheckBinance = value => {
    if (invalidArray.includes('hasBinance')) {
      const localInvalidArray = invalidArray.filter(
        item => item !== 'hasBinance'
      );
      setInvalidArray(localInvalidArray);
    }
    setHasBinance(value);
  };

  const validityCheck = () => {
    const localInvalidArray = [];
    // name
    if (formData.name === '' || formData.name === null)
      localInvalidArray.push('name');
    // surname
    if (formData.surname === '' || formData.surname === null)
      localInvalidArray.push('surname');
    // tax_code
    if (formData.tax_code === '' || formData.tax_code === null)
      localInvalidArray.push('tax_code');
    // place
    if (formData.place_id === '' || formData.place_id === null) {
      localInvalidArray.push('place');
      setFormData({
        ...formData,
        address: ''
      });
    }
    // mobile
    if (formData.mobile === '' || formData.mobile === null)
      localInvalidArray.push('mobile');
    // hasBinance
    if (hasBinance === null) localInvalidArray.push('hasBinance');
    //tos
    if (!acceptedTos) localInvalidArray.push('acceptedTos');
    // hasBinance
    if (hasBinance === null) localInvalidArray.push('hasBinance');
    // check and return
    setInvalidArray(localInvalidArray);
    return localInvalidArray.length === 0;
  };

  const createKycSession = async () => {
    if (!validityCheck()) return;
    setDisableButton(true);
    let form = new FormData();
    form.append('first_name', formData.name);
    form.append('last_name', formData.surname);
    form.append('tax_code', formData.tax_code);
    form.append('place_id', formData.place_id);
    form.append('mobile_number', formData.mobile);
    form.append('has_binance', hasBinance);
    let kycSessionResponse = await endpointInterface(
      lang,
      'backend',
      'createKycSession',
      'post',
      true,
      form
    );
    if (!kycSessionResponse.validResponse) {
      if (kycSessionResponse.errorID === 'invalidTaxCode') {
        invalidArray.push('tax_code');
        setInvalidArray(invalidArray);
        return;
      }
      toast.error(kycSessionResponse.responseMessage, {
        closeButton: false
      });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
      setDisableButton(false);
    } else {
      if (KYCComplete) window.location.reload();
      else {
        const Onfido = await import('onfido-sdk-ui');
        onfido = Onfido.init({
          useModal: true,
          isModalOpen: true,
          onModalRequestClose: () => {
            // Update options with the state of the modal
            onfido.setOptions({ isModalOpen: false });
            if (KYCComplete) handleGetKYC();
            else {
              setDisableButton(false);
              setDoKYCShow(true);
            }
          },
          token: kycSessionResponse.data.sdk_token,
          workflowRunId: kycSessionResponse.data.workflow_run_id,
          onComplete: () => {
            trackEvent('kyc', {
              status_event: 'completed',
              where: 'wallet page'
            });
            KYCComplete = true;
          },
          onUserExit: () => {
            trackEvent('kyc', {
              status_event: 'user exit',
              where: 'wallet page'
            });
            setDisableButton(false);
            setDoKYCShow(true);
            setOnError(true);
          },
          onError: () => {
            trackEvent('kyc', {
              status_event: 'onfido error',
              where: 'wallet page'
            });
            setDisableButton(false);
            setDoKYCShow(true);
            setOnError(true);
          }
        });
        setDoKYCShow(false);
      }
    }
  };

  return (
    <Modal
      show={doKYCShow}
      onHide={() => handleHideModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant="white" className="pe-sm-5">
        <Wizard step={step} className="pt-3" />
      </Modal.Header>
      {processingKYC ? (
        stopProcessing ? (
          <Modal.Body className="text-center">
            <h5>{textLang.endProcessing[lang]}</h5>
            <h5>{textLang.endProcessing1[lang]}</h5>
          </Modal.Body>
        ) : (
          <Modal.Body>
            <div style={{ height: '150px', paddingTop: '50px' }}>
              <LoadingSpinner
                subText={textLang.processing[lang]}
              ></LoadingSpinner>
            </div>
          </Modal.Body>
        )
      ) : onError ? (
        <>
          <Modal.Body className="text-center">
            <h5>{textLang.retryText[lang]}</h5>
            <h5>
              {textLang.retryText1[lang]}
              <a href="mailto:support@hodlie.net"> support@hodlie.net</a>
            </h5>
          </Modal.Body>
          <Modal.Footer>
            <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
              <IconButton
                variant="outline-success"
                className="me-2 mb-1 "
                icon="user"
                iconAlign="left"
                transform="shrink-3"
                onClick={() => setOnError(false)}
              >
                {textLang.retry[lang]}
              </IconButton>
            </Flex>
          </Modal.Footer>
        </>
      ) : canStartTrial ? (
        <Row className="justify-content-center p-2">
          <h5 className="text-center">{textLang.checkTitle[lang]}.</h5>
          <h5 className="text-center">{textLang.checkTitle2[lang]}</h5>
          <h5 className="text-center text-warning">
            {textLang.checkTitle3[lang]}
          </h5>
          <Col
            sm={6}
            className={`mt-5 pb-5 pb-sm-0 border-${
              window.innerWidth < breakpoints['sm'] ? 'bottom' : 'end'
            } border-secondary`}
          >
            <h4 className="text-center pb-4">{textLang.goToGuideText[lang]}</h4>
            <p className="text-center pb-2 h-sm-25">
              {textLang.goToGuideText1[lang]}
            </p>
            <Flex justifyContent="center">
              <Button
                as={Link}
                variant="outline-primary"
                className=""
                to={{
                  pathname: 'https://www.binance.com/'
                }}
                target="_blank"
              >
                {textLang.goToGuideButton[lang]}
              </Button>
            </Flex>
          </Col>
          <Col sm={6} className="mt-5">
            <h4 className="text-center">{textLang.waitingText[lang]}</h4>
            <p className="text-center pb-2 h-sm-25">
              {textLang.waitingText1[lang]}
            </p>
            <Flex justifyContent="center">
              <Button
                as={Link}
                variant="outline-primary"
                className=""
                to="virtual-mode"
              >
                {textLang.waitingButton[lang]}
              </Button>
            </Flex>
          </Col>
        </Row>
      ) : (
        <>
          <Modal.Body>
            <h4 className="text-center">{textLang.title[lang]}</h4>
            <Row className="justify-content-center">
              <Col md={6} className="">
                <Form.Label>{textLang.name[lang]}</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="text"
                    value={formData.name}
                    name="name"
                    onChange={handleFieldChange}
                    isInvalid={invalidArray.includes('name')}
                  />
                  <FormControl.Feedback type="invalid">
                    {textLang.error_name[lang]}
                  </FormControl.Feedback>
                </InputGroup>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={6} className="">
                <Form.Label>{textLang.surname[lang]}</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="text"
                    value={formData.surname}
                    name="surname"
                    onChange={handleFieldChange}
                    isInvalid={invalidArray.includes('surname')}
                  />
                  <FormControl.Feedback type="invalid">
                    {textLang.error_surname[lang]}
                  </FormControl.Feedback>
                </InputGroup>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={6} className="">
                <Form.Label>{textLang.tax_code[lang]}</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="text"
                    value={formData.tax_code}
                    name="tax_code"
                    onChange={handleFieldChange}
                    isInvalid={invalidArray.includes('tax_code')}
                  />
                  <FormControl.Feedback type="invalid">
                    {textLang.error_tax_code[lang]}
                  </FormControl.Feedback>
                </InputGroup>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={6} className="mb-3">
                <Form.Label>{textLang.address[lang]}</Form.Label>
                <PlacesAutocomplete
                  value={formData.address}
                  onChange={handlePlaceChange}
                  onSelect={handlePlaceChange}
                  searchOptions={searchOptions}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading
                  }) => (
                    <>
                      <FormControl
                        {...getInputProps({
                          className: 'location-search-input'
                        })}
                        name="place"
                        isInvalid={invalidArray.includes('place')}
                      />
                      <FormControl.Feedback type="invalid">
                        {textLang.placeFeedback[lang]}
                      </FormControl.Feedback>
                      {showSuggestions && (
                        <div className="autocomplete-dropdown-container border rounded">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion, index) => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            const style = suggestion.active
                              ? {
                                  backgroundColor: 'var(--falcon-300)',
                                  cursor: 'pointer'
                                }
                              : {
                                  backgroundColor: 'var(--falcon-200)',
                                  cursor: 'pointer'
                                };
                            return (
                              <div
                                key={index}
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style
                                })}
                                onClick={() => handleSelect(suggestion)}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      )}
                    </>
                  )}
                </PlacesAutocomplete>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={6} className="">
                <Form.Label>{textLang.mobile[lang]}</Form.Label>
                <InputGroup className="mb-3">
                  <FormControl
                    type="text"
                    value={formData.mobile}
                    name="mobile"
                    onChange={handleFieldChange}
                    isInvalid={invalidArray.includes('mobile')}
                  />
                  <FormControl.Feedback type="invalid">
                    {textLang.error_mobile[lang]}
                  </FormControl.Feedback>
                </InputGroup>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col
                md={6}
                className="pb-2"
                style={{ display: 'flex', flexDirection: 'row' }}
              >
                <Form.Label>
                  <p
                    className="m-1 pe-1"
                    dangerouslySetInnerHTML={{
                      __html: textLang.has_binance[lang]
                    }}
                  ></p>
                </Form.Label>
                <div>
                  <Form.Check
                    inline
                    type="radio"
                    id="item1Radio"
                    label={textLang.yes[lang]}
                    name="inline-radio"
                    checked={hasBinance === true}
                    onClick={() => handleCheckBinance(true)}
                    isInvalid={invalidArray.includes('hasBinance')}
                  />
                  <Form.Check
                    inline
                    type="radio"
                    id="item2Radio"
                    label={textLang.no[lang]}
                    name="inline-radio"
                    checked={hasBinance === false}
                    onClick={() => handleCheckBinance(false)}
                    isInvalid={invalidArray.includes('hasBinance')}
                  />
                </div>
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col
                md={6}
                className="pb-2"
                style={{ display: 'flex', flexDirection: 'row' }}
                onClick={handleAcceptTOS}
              >
                <Form.Check
                  type="checkbox"
                  className="me-2"
                  style={{ cursor: 'pointer' }}
                  name="acceptedTos"
                  checked={acceptedTos}
                  isInvalid={invalidArray.includes('acceptedTos')}
                />
                <Form.Label>
                  <p
                    className="m-0 p-0"
                    dangerouslySetInnerHTML={{
                      __html: textLang.tos[lang]
                    }}
                  ></p>
                </Form.Label>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Flex className="col-6 m-0" style={{ justifyContent: 'start' }}>
              <IconButton
                variant="outline-danger"
                className="me-2 mb-1 "
                icon="times"
                iconAlign="left"
                transform="shrink-3"
                onClick={() => setDoKYCShow(false)}
              >
                {textLang.close[lang]}
              </IconButton>
            </Flex>
            <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
              <IconButton
                variant="outline-success"
                className="me-2 mb-1 "
                icon="user"
                iconAlign="left"
                transform="shrink-3"
                onClick={() => createKycSession()}
                disabled={disableButton}
              >
                {textLang.verify[lang]}
              </IconButton>
            </Flex>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
};

DoKYC.propTypes = {
  doKYCShow: PropTypes.bool.isRequired,
  setDoKYCShow: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  setKycStatus: PropTypes.func.isRequired,
  setAddKeysShow: PropTypes.func
};

export default DoKYC;
