import React, { useState, useContext, useEffect } from 'react';
import { Row, Card, Spinner, Col, Button } from 'react-bootstrap';
import { VirtualMode as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';
import WalletHeader from './WalletHeader';
import BotRow from 'pages/common/BotRow';
import 'assets/scss/custom/tables.scss';
import 'assets/scss/custom/walletPage.scss';
import PageHeader from 'components/common/PageHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { canShowRoute, teamLevel } from 'staticData/common';
import SubSectionHeader from 'pages/common/SubSectionHeader';
import { currencyMap } from 'services/coins/common';
import PortfolioRowData from '../common/portfolioRow/PortfolioRowData';
import PricingModal from 'pages/Pricing/modals';
import { useHistory } from 'react-router-dom';

const VirtualMode = () => {
  const {
    config: { lang, currency },
    coinValues,
    userInfos
  } = useContext(AppContext);
  const [botList, setBotList] = useState([]);
  const [portfolioList, setPortfolioList] = useState([]);
  const [dynamicCoins, setDynamicCoins] = useState({});
  const [loadedBotList, setLoadedBotList] = useState(false);
  const [showUpToPro, setShowUpToPro] = useState(false);
  const routerHistory = useHistory();

  const getBotData = async () => {
    var params = { virtual: true };
    let walletStatsResponse = await endpointInterface(
      lang,
      'backend',
      'getBots',
      'get',
      true,
      params,
      true
    );
    if (walletStatsResponse.validResponse) {
      return walletStatsResponse.data;
    } else {
      toast.error(walletStatsResponse.responseMessage, { closeButton: false });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
      return {};
    }
  };

  const transformArray = arr => {
    const bots = [];
    const portfolios = [];

    arr.forEach(data => {
      if (data.status !== 'TERMINATED') {
        const { assets, last_stat, allocation_changes, ...bot_details } = data;
        const addedCapital = (allocation_changes || []).reduce(
          (sum, item) => sum + item.metadata?.eqv[currencyMap[currency]] || 0,
          0
        );
        const transformedData = {
          coins: assets,
          last_stat: last_stat,
          bot_details: bot_details,
          added_capital: addedCapital,
          wallet: {},
          snapshots: allocation_changes
        };

        if (bot_details?.category === 'bot') {
          bots.push(transformedData);
        } else if (bot_details?.category === 'portfolio') {
          portfolios.push(transformedData);
        }
      }
    });
    return { bots, portfolios };
  };

  const manageBotsData = async () => {
    let localWalletStat = await getBotData();
    let localBotList = transformArray(localWalletStat);
    setPortfolioList(localBotList.portfolios);
    setBotList(localBotList.bots);
    setLoadedBotList(true);
  };

  const handleHidePricingModal = show => {
    setShowUpToPro(show);
    if (!show) routerHistory.push('/');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        manageBotsData();
      } catch (error) {
        console.error(error.message);
      }
    };
    if (coinValues.current) {
      setDynamicCoins(coinValues.current);
    }
    // check if user is in testAB and if he can see virtual page (not ended trial)
    if (!canShowRoute('virtual-page', userInfos)) setShowUpToPro(true);
    else fetchData();
  }, []);

  useEffect(async () => {
    var intervalCoinsData = setInterval(async () => {
      if (coinValues.current) {
        setDynamicCoins(coinValues.current);
      }
    }, 1000);

    return () => {
      clearInterval(intervalCoinsData);
    };
  }, []);

  return (
    <>
      <PricingModal
        showModal={showUpToPro}
        setShowModal={handleHidePricingModal}
        whatToDo={userInfos?.pro_tier === 0 ? 'newSub' : 'upgrade'}
        newProTier={0}
        type="sentiment"
      />
      <Row className="mb-3">
        <Card className="pe-0">
          <Card.Header className="position-relative">
            <h4 className="text-800 mb-0">{textLang.title[lang]}</h4>
          </Card.Header>
        </Card>
      </Row>
      {!showUpToPro && <WalletHeader />}
      {(!showUpToPro || userInfos?.level >= teamLevel) && (
        <>
          <SubSectionHeader title={<Flex>AI Portfolio</Flex>} />
          {loadedBotList ? (
            portfolioList.length ? (
              portfolioList.map((bot, index) => (
                <PortfolioRowData
                  portfolioData={bot}
                  key={index}
                  dynamicCoins={dynamicCoins}
                  showChart={false}
                  exchange="virtual"
                />
              ))
            ) : (
              <Row className={'pb-3'}>
                <Col xs={12}>
                  <PageHeader
                    as={Link}
                    to="/invest?type=portfolio&exchange=virtual"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    title={textLang.addPortfolio[lang]}
                    description={textLang.addTextPortfolio[lang]}
                    className="mb-3"
                    col={{ lg: 12 }}
                  >
                    <Button variant="link" size="sm" className="ps-0">
                      {textLang.addButton[lang]}
                      <FontAwesomeIcon
                        icon="chevron-right"
                        className="ms-1 fs--2"
                      />
                    </Button>
                  </PageHeader>
                </Col>
              </Row>
            )
          ) : (
            <Flex className="justify-content-center mt-5">
              <Spinner />
            </Flex>
          )}
        </>
      )}
      {(!showUpToPro || userInfos?.level >= teamLevel) && (
        <>
          {loadedBotList ? (
            botList.length > 0 && (
              <>
                <SubSectionHeader className="mt-3" title={'AI Agents'} />
                {botList.map((bot, index) => (
                  <BotRow
                    bot={bot}
                    key={index}
                    dynamicCoins={dynamicCoins}
                    exchange="virtual"
                  />
                ))}
              </>
            )
          ) : (
            <Flex className="justify-content-center mt-5">
              <Spinner />
            </Flex>
          )}
        </>
      )}
    </>
  );
};

export default VirtualMode;
