import PropTypes from 'prop-types';
import React, { useContext, useState, useEffect } from 'react';
import {
  Col,
  Row,
  Modal,
  InputGroup,
  FormControl,
  Form,
  Spinner,
  Button
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import { toast } from 'react-toastify';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import Flex from 'components/common/Flex';
import { AddKeys as textLang } from 'staticData/languages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import robot from 'assets/img/animated-icons/robot.json';
import Lottie from 'lottie-react';
import AppContext from 'context/Context';
import Wizard from 'pages/common/wizard/Wizard';
import { useHistory } from 'react-router-dom';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import { cleanCache } from 'services/cache';
import { trackEvent } from 'services/externals/mixpanel';
import IconAlert from 'components/common/IconAlert';
import { backend_host } from 'router/routes';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { breakpoints } from 'helpers/utils';

const AddKeys = ({
  addKeysShow,
  setAddKeysShow,
  exchange,
  setWalletData = () => null,
  walletData = { [exchange]: {} },
  walletMapData,
  realoadUserInfo,
  step,
  setStep,
  error_id = '',
  connected = false
}) => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  const routerHistory = useHistory();
  const checkConnected =
    userInfos.connected_exchanges.includes(exchange.toLowerCase()) || connected;
  const [showCheck, setShowCheck] = useState(checkConnected);
  const [disabledButton, setDisabledButton] = useState(false);
  const [showKeysProcedure, setShowKeysProcedure] = useState(false);
  const [canStartTrial, setCanStartTrial] = useState(false);
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    key: '',
    secret: ''
  });

  const handleFieldChange = e => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value
    });
  };

  const handleCloseCheck = async createBot => {
    setAddKeysShow(false);
    if (!createBot) await realoadUserInfo();
    setTimeout(() => {
      setShowCheck(false);
    }, 1000);
    if (createBot) routerHistory.push('/invest');
  };

  const buttonStyle = {
    border: '2px solid #efb300',
    borderRadius: '3px',
    color: 'var(--falcon-body)',
    backgroundColor: '#efb300',
    padding: '0.375rem 0.75rem',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    cursor: 'pointer'
  };

  const buttonHoverStyle = {
    backgroundColor: '#efb300',
    color: 'white'
  };

  const handleFastAPI = async () => {
    const baseURL = 'https://accounts.binance.com/en/oauth/authorize';
    const BINANCE_CLIENT_ID = 'MbdfLIZ1ja';
    const params = new URLSearchParams({
      response_type: 'code',
      client_id: BINANCE_CLIENT_ID,
      redirect_uri: `${backend_host}/binance/webhook`,
      state: userInfos.tracking_id,
      scope: 'account:status,create:apikey,user:openId'
    });
    await cleanCache(0);
    window.location.href = `${baseURL}?${params.toString()}`;
  };

  const setExchangeCredentials = async () => {
    setDisabledButton(true);
    let form = new FormData();
    form.append('exchange', exchange);
    form.append('credentials', JSON.stringify(credentials));
    let setExchangeCredentialsRepsonse = await endpointInterface(
      lang,
      'backend',
      'setExchangeCredentials',
      'post',
      true,
      form
    );
    setDisabledButton(false);
    if (setExchangeCredentialsRepsonse.validResponse) {
      trackEvent('exchangeKeys', {
        status_event: 'add'
      });
      await cleanCache(0);
      let tmpWallet = walletData;
      tmpWallet[exchange]['connected'] = true;
      setWalletData(tmpWallet);
      await getVirtualTrial();
      setShowCheck(true);
      setStep(step + 1);
    } else {
      var check = true;
      if (check) {
        toast.error(setExchangeCredentialsRepsonse.responseMessage, {
          closeButton: false
        });
        setTimeout(() => {
          toast.dismiss();
        }, 5000);
      }
    }
  };

  const getVirtualTrial = async () => {
    setLoading(true);
    let resp = await endpointInterfaceV2({
      internalEndpoint: 'virtual_trial',
      httpMethod: 'get',
      usingCredentials: true,
      showError: false
    });
    if (resp.validResponse) {
      if (resp.data.can_start_trial) setCanStartTrial(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (error_id) setShowKeysProcedure(true);
    if (connected) getVirtualTrial();
  }, [error_id]);

  return (
    <Modal
      show={addKeysShow}
      onHide={() =>
        showCheck ? handleCloseCheck(false) : setAddKeysShow(false)
      }
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant="white" className="pe-sm-5">
        <Wizard step={step} className="pt-3" />
      </Modal.Header>
      <Modal.Body>
        {showCheck ? (
          <Row
            className={classNames('justify-content-center', {
              'mb-5': canStartTrial
            })}
          >
            {loading ? (
              <Col
                as={Flex}
                className="justify-content-center align-items-center min-vh-25"
              >
                <Spinner />
              </Col>
            ) : canStartTrial ? (
              <>
                <h5 className="text-center">{textLang.checkTitle[lang]},</h5>
                <h5 className="text-center">
                  {textLang.checkTitle2[lang]}
                  <span className="text-warning">
                    {textLang.checkTitle3[lang]}
                  </span>
                  {textLang.checkTitle4[lang]}
                </h5>
                <Col
                  sm={6}
                  className={`mt-5 pb-5 pb-sm-0 border-${
                    window.innerWidth < breakpoints['sm'] ? 'bottom' : 'end'
                  } border-secondary`}
                >
                  <h4 className="text-center pb-4">
                    {textLang.goToGuideText[lang]}
                  </h4>
                  <p className="text-center pb-2 h-sm-25">
                    {textLang.goToGuideText1[lang]}
                  </p>
                  <Flex justifyContent="center">
                    <Button
                      as={Link}
                      variant="outline-primary"
                      className=""
                      to={{
                        pathname:
                          'https://support.hodlie.ai/depositare-fiat-su-binance'
                      }}
                      target="_blank"
                    >
                      {textLang.goToGuideButton[lang]}
                    </Button>
                  </Flex>
                </Col>
                <Col sm={6} className="mt-5">
                  <h4 className="text-center">{textLang.waitingText[lang]}</h4>
                  <p className="text-center pb-2 h-sm-25">
                    {textLang.waitingText1[lang]}
                  </p>
                  <Flex justifyContent="center">
                    <Button
                      as={Link}
                      variant="outline-primary"
                      className=""
                      to="virtual-mode"
                    >
                      {textLang.waitingButton[lang]}
                    </Button>
                  </Flex>
                </Col>
              </>
            ) : (
              <>
                <h4 className="text-center">{textLang.checkTitle[lang]}</h4>
                <h4 className="text-center">{textLang.checkTitle1[lang]}</h4>
                <Lottie
                  animationData={robot}
                  loop={true}
                  style={{ height: '200px', width: '200px' }}
                />
              </>
            )}
          </Row>
        ) : (
          <>
            <h4 className="text-center mt-1 pb-4">
              {textLang.connect[lang] + exchange}
            </h4>
            {error_id ? (
              <IconAlert key="danger" variant="danger" className="ms-2">
                {textLang.errorMsg?.[error_id]?.[lang] ||
                  textLang.errorMsg['000001'][lang]}
              </IconAlert>
            ) : null}
            <Row className="justify-content-center pb-3">
              <Col md={6} className="pb-2 text-center">
                <span
                  onClick={handleFastAPI}
                  style={buttonStyle}
                  onMouseOver={e =>
                    Object.assign(e.target.style, buttonHoverStyle)
                  }
                  onMouseOut={e => Object.assign(e.target.style, buttonStyle)}
                >
                  {textLang.fastApiConnect[lang]}
                </span>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <small
                  className="pointerCursor"
                  onClick={() => setShowKeysProcedure(!showKeysProcedure)}
                >
                  {textLang.notButton[lang]}
                  <FontAwesomeIcon
                    icon={`caret-${showKeysProcedure ? 'up' : 'down'}`}
                    className="ms-2"
                  />
                </small>
              </Col>
            </Row>
            {showKeysProcedure && (
              <>
                <Row className="justify-content-center">
                  <Col md={8}>
                    <hr />
                  </Col>
                </Row>
                <Row className="justify-content-center">
                  {addKeysShow && (
                    <>
                      <h6 className="text-center pb-2">
                        <FontAwesomeIcon icon="link" className="me-2" />
                        {textLang.follow[lang]}
                        <a
                          href={walletMapData[exchange].guideLink}
                          target="__blank"
                        >
                          {exchange + textLang.guide[lang]}
                        </a>
                        {textLang.toConn[lang]}
                        {textLang.yourWall[lang]}
                      </h6>
                    </>
                  )}
                </Row>
                <Row className="justify-content-center">
                  <Col md={10} className="pb-2">
                    <div className="border rounded p-3">
                      <Form.Label>API Key</Form.Label>
                      <InputGroup className="mb-3">
                        <FormControl
                          type="text"
                          name="key"
                          id="key"
                          autoComplete="new-password"
                          onChange={handleFieldChange}
                        />
                      </InputGroup>
                      <Form.Label>Secret Key</Form.Label>
                      <InputGroup className="mb-3">
                        <FormControl
                          type="text"
                          name="secret"
                          id="secret"
                          autoComplete="new-password"
                          onChange={handleFieldChange}
                        />
                      </InputGroup>
                    </div>
                  </Col>
                </Row>
                <Flex className="col-12 mt-3 justify-content-center">
                  <IconButton
                    onClick={() => setExchangeCredentials()}
                    variant="success"
                    className="me-2 mb-1 "
                    icon={disabledButton ? '' : 'check'}
                    iconAlign="left"
                    transform="shrink-3"
                    disabled={
                      !credentials.key || !credentials.secret || disabledButton
                    }
                  >
                    {disabledButton ? (
                      <Flex>
                        <Spinner
                          animation="border"
                          className="me-2 mt-1"
                          style={{ width: '1rem', height: '1rem' }}
                        ></Spinner>
                        {textLang.confirm[lang]}
                      </Flex>
                    ) : (
                      textLang.confirm[lang]
                    )}
                  </IconButton>
                </Flex>
              </>
            )}
          </>
        )}
      </Modal.Body>
      {showCheck && !loading && !canStartTrial && (
        <Modal.Footer>
          <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
            <IconButton
              onClick={() => handleCloseCheck(true)}
              variant="outline-success"
              className="me-2 mb-1 "
              icon="robot"
              iconAlign="left"
              transform="shrink-3"
            >
              {textLang.create[lang]}
            </IconButton>
          </Flex>
        </Modal.Footer>
      )}
    </Modal>
  );
};

AddKeys.propTypes = {
  addKeysShow: PropTypes.bool.isRequired,
  setAddKeysShow: PropTypes.func.isRequired,
  exchange: PropTypes.string.isRequired,
  walletData: PropTypes.object.isRequired,
  setWalletData: PropTypes.func.isRequired,
  walletMapData: PropTypes.object.isRequired,
  realoadUserInfo: PropTypes.func.isRequired,
  step: PropTypes.number.isRequired,
  setStep: PropTypes.func.isRequired,
  error_id: PropTypes.string.isRequired,
  connected: PropTypes.bool
};

export default AddKeys;
