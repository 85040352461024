import React from 'react';
import PropTypes from 'prop-types';
import WizardLayout from './WizardLayout';
import AuthWizardProvider from './AuthWizardProvider';

const Wizard = ({ step, ...rest }) => {
  return (
    <AuthWizardProvider>
      <WizardLayout step={step} {...rest} />
    </AuthWizardProvider>
  );
};

Wizard.propTypes = {
  step: PropTypes.number
};

export default Wizard;
