import React, { useContext } from 'react';
import AuthCardLayout from './AuthCardLayout';
import RegistrationForm from './RegistrationForm';
import { login as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Registration = ({ getInfo }) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  return (
    <AuthCardLayout
      leftSideContent={
        <p className="text-white">
          {textLang.account[lang]}
          <br />
          <Link
            className="text-white text-decoration-underline"
            to={'login' + window.location.search}
          >
            {textLang.login[lang]}
          </Link>
        </p>
      }
      footer={false}
    >
      <RegistrationForm hasLabel getInfo={getInfo} />
    </AuthCardLayout>
  );
};

Registration.propTypes = {
  getInfo: PropTypes.func
};

export default Registration;
