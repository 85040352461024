import React, { forwardRef, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import {
  GridComponent,
  TooltipComponent,
  LegendComponent
} from 'echarts/components';
import { coinsData } from 'services/coins/common';
import AppContext from 'context/Context';
import { CardDropdown as textLang, monthsList } from 'staticData/languages';
import { getColor, rgbaColor, breakpoints } from 'helpers/utils';
import { calcTotalTimeWeightedReturn } from 'services/chartsData/functions';

echarts.use([LineChart, TooltipComponent, GridComponent, LegendComponent]);

const Y_AXIS_INTERVAL = 100;

const getMin = dataArrays => {
  const dataMin = Math.min(...dataArrays.flat().filter(value => value != null));
  let min = Math.max(0, Math.round(0.95 * dataMin));
  min = min - (min % Y_AXIS_INTERVAL);
  return min;
};

const getMax = dataArrays => {
  let max = Math.round(
    1.05 * Math.max(...dataArrays.flat().filter(value => value != null))
  );
  max = max + (Y_AXIS_INTERVAL - (max % Y_AXIS_INTERVAL));
  return max;
};

const formatDate = (dateString, lang) => {
  const date = new Date(dateString);
  const months = monthsList.monthsLong[lang];

  const day = date.getDate();
  const month = months[date.getMonth()];
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, '0');

  return `${day} ${month} h${hours}:${minutes}`;
};

const orderParams = array => {
  return array.sort((a, b) => {
    if (a.seriesName === 'HODLIE') return -1;
    if (b.seriesName === 'HODLIE') return 1;

    return b.value - a.value;
  });
};

const tooltipFormatter = (params, data, currency, marklinesPoints, lang) => {
  let tooltipItem = ``;
  let hasValidValue = false;
  params = orderParams(params);
  const startValue = data.HODLIE.values.find(value => value != null);

  params.forEach(el => {
    if (el.seriesName === 'HODLIE' && marklinesPoints.includes(el.dataIndex)) {
      tooltipItem =
        tooltipItem +
        `<h6>
        ${textLang.addedCapital[lang]}: ${data.ADD_CAPITAL.values[
          el.dataIndex
        ].toFixed(2)} ${currency}
      </h6>`;
    }
    if (
      el.seriesName !== 'ZERO' &&
      el.seriesName !== 'ADD_CAPITAL' &&
      el.value != null
    ) {
      hasValidValue = true;

      // iteration on marklinepoints to get the eventual added capital
      const eventsArray = [];
      marklinesPoints.forEach(index => {
        if (el.dataIndex >= index) {
          let event = {
            preValue:
              data[el.seriesName].normalizedValues[index > 0 ? index - 1 : 0],
            afterValue: data[el.seriesName].normalizedValues[index],
            valueAdded: data.ADD_CAPITAL.values[index]
          };
          eventsArray.push(event);
        }
      });
      const percent = calcTotalTimeWeightedReturn(
        startValue,
        eventsArray,
        el.value
      );

      tooltipItem =
        tooltipItem +
        `<h6 class="fs--1 text-700 d-flex align-items-center">
        <div class="dot me-2" style="background-color:${el.borderColor}"></div>
        ${coinsData[el.seriesName]?.name || 'Hodlie'} : 
        ${percent.toFixed(2)}%
      </h6>`;
    }
  });
  if (hasValidValue)
    return `<div class='ms-1'>
            <h6>${formatDate(params[0].axisValue, lang)}</h6>
            ${tooltipItem}
          </div>`;
};

const getOption = (data, currency) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const coinNames = Object.keys(data).filter(
    key => key !== 'TIMESTAMP' && key !== 'ADD_CAPITAL'
  );
  const dataArrays = coinNames.map(coin => data[coin].normalizedValues);
  const initialHodlieValue = data.HODLIE.values.find(value => value != null);

  // HODLIE is always first
  const hodlieIndex = coinNames.indexOf('HODLIE');
  if (hodlieIndex > -1) {
    coinNames.splice(hodlieIndex, 1);
    coinNames.unshift('HODLIE');
  }

  const marklinesPoints = data.ADD_CAPITAL.normalizedValues.reduce(
    (accumulator, value, index) => {
      if (value !== null) {
        accumulator.push(index);
      }
      return accumulator;
    },
    []
  );

  const marklinesArray = data.ADD_CAPITAL.normalizedValues.reduce(
    (accumulator, value, index) => {
      if (value !== null) {
        accumulator.push([
          {
            xAxis: index,
            yAxis: initialHodlieValue
          },
          { xAxis: index, yAxis: value }
        ]);
      }
      return accumulator;
    },
    []
  );

  const seriesData = coinNames.map(coin => ({
    name: coin,
    type: 'line',
    data: data[coin].normalizedValues,
    lineStyle: {
      color: coinsData[coin]?.color || getColor('primary'),
      width: coin === 'HODLIE' ? 3 : 1,
      opacity: coin === 'HODLIE' ? 1 : 0.25
    },
    itemStyle: {
      borderColor: coinsData[coin]?.color || getColor('primary'),
      borderWidth: 2
    },
    symbol: 'none',
    smooth: false,
    emphasis: {
      scale: true
    },
    markLine: {
      show: coin === 'HODLIE',
      silent: true,
      symbol: ['none', 'none'],
      lineStyle: {
        type: 'dashed',
        color: rgbaColor(getColor('primary')),
        width: 1
      },
      data: marklinesArray
    }
  }));

  // Add zero line
  seriesData.push({
    name: 'ZERO',
    type: 'line',
    data: data.TIMESTAMP.map(() => initialHodlieValue),
    lineStyle: {
      color: 'white',
      type: 'dashed',
      width: 1
    },
    symbol: 'none',
    smooth: false,
    emphasis: {
      scale: false
    },
    z: -1
  });

  return {
    title: {
      text: '',
      textStyle: {
        fontWeight: 500,
        fontSize: 13,
        fontFamily: 'poppins',
        color: getColor('800')
      }
    },
    color: getColor('white'),
    tooltip: {
      show: window.innerWidth >= breakpoints['sm'],
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: params =>
        tooltipFormatter(params, data, currency, marklinesPoints, lang),
      transitionDuration: 0
    },
    legend: {
      show: false,
      data: coinNames,
      textStyle: { color: getColor('800') }
    },
    xAxis: {
      type: 'category',
      data: data.TIMESTAMP,
      boundaryGap: false,
      axisPointer: {
        lineStyle: {
          color: getColor('300'),
          type: 'dashed'
        }
      },
      axisLine: {
        lineStyle: {
          color: getColor('300'),
          type: 'solid'
        }
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('400'),
        margin: 15,
        formatter: value =>
          new Date(value).getDate() +
          ' ' +
          monthsList.months[lang][new Date(value).getMonth()]
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('300'),
          type: 'dashed'
        }
      }
    },
    yAxis: {
      type: 'value',
      axisPointer: { show: false },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('300')
        }
      },
      min: getMin(dataArrays),
      max: getMax(dataArrays),
      boundaryGap: false,
      axisLabel: {
        show: marklinesPoints.length === 0,
        color: getColor('400'),
        margin: 5,
        formatter: value =>
          value
            ? `${((Number(value) / initialHodlieValue) * 100 - 100).toFixed(
                0
              )}%`
            : '0.00%'
      },
      axisTick: { show: false },
      axisLine: { show: false }
    },
    series: seriesData,
    grid: { right: '8px', left: '40px', bottom: '15%', top: '5%' }
  };
};

const BenchmarkChart = forwardRef(({ data, currency = '$', ...rest }, ref) => {
  const [reset, setReset] = useState(true);

  useEffect(() => {
    setReset(false);
  }, [data]);

  useEffect(() => {
    setTimeout(() => {
      setReset(true);
    }, 10);
  }, [reset]);

  return (
    <div style={{ minHeight: '20rem' }}>
      {reset && (
        <ReactEChartsCore
          echarts={echarts}
          ref={ref}
          option={getOption(data, currency)}
          {...rest}
        />
      )}
    </div>
  );
});

BenchmarkChart.propTypes = {
  data: PropTypes.object.isRequired,
  currency: PropTypes.string,
  selectedYear: PropTypes.string,
  setSelectedYear: PropTypes.func,
  showAll: PropTypes.bool,
  setShowAll: PropTypes.func
};

export default BenchmarkChart;
