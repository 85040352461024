import React, { useRef, useState, useEffect, useMemo, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Col,
  Row,
  ButtonGroup,
  ToggleButton,
  Dropdown,
  Spinner
} from 'react-bootstrap';
import Flex from 'components/common/Flex';
import LinePaymentChart from './LinePaymentChart';
import LinePaymentChartBenchmark from './LinePaymentChartBenchmark';
import LoadingSpinner from 'components/utilities/LoadingSpinner';
import { endpointInterfaceV2 } from 'services/endpointInterface/endpointInterfaceV2';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  walletNames,
  orderObjByDateKey,
  LinePaymentButtonMap as buttonMap,
  botGeneralInfo
} from 'staticData/common';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import AppContext from 'context/Context';
import { LinePayment as textLang } from 'staticData/languages';
import { currencyMap } from 'services/coins/common';
import { calculateCoinsValue } from 'services/coins/functions';
import { walletImgRender } from 'services/coins/components';
import { trackEvent } from 'services/externals/mixpanel';
import { portfolioList } from 'pages/CreateBot/staticData/portfolioList';

const LinePayment = ({
  allWallet,
  dynamicBotMap,
  generalWallet = 0,
  h100 = false,
  dynamicLoaded = true,
  loaded,
  setLoaded,
  singleBotName = 'WALLET',
  singleBotID = null,
  exchange = 'binance',
  parentLoaded,
  simplifyChart = false,
  singleBotBenchmark = null,
  triggerLoadCheckpoint = { counter: null },
  setTriggerLoadCheckpoint = null,
  multiBot = false,
  portfolioBgStyle = null
}) => {
  const {
    config: { lang, currency },
    coinValues
  } = useContext(AppContext);
  const chartRef = useRef(null);
  const chartBenchmarkRef = useRef(null);
  // can get the chart granularity from query parameter
  const queryParameters = new URLSearchParams(window.location.search);
  const chartGranularity = queryParameters.get('cg');
  const [selectedBotID, setSelectedBotID] = useState(
    singleBotID ? singleBotID : exchange
  );
  const [selectedBotGain, setSelectedBotGain] = useState();
  const [selectedBotValue, setSelectedBotValue] = useState();
  const [zoomActive, setZoomActive] = useState(false);
  const [showBenchmarkChart, setShowBenchmarkChart] = useState(false);
  const [showCheckpointData, setShowCheckpointData] = useState(false);
  const [showPercentage, setShowPercentage] = useState(true);
  const [displayedWallet, setDisplayedWallet] = useState([]);
  const [showWallet, setShowWallet] = useState(false);
  const [showBots, setShowBots] = useState(false);
  const [activeRadio, setActiveRadio] = useState(
    Object.keys(buttonMap).includes(chartGranularity) ? chartGranularity : 'All'
  );
  const [dynamicCoins, setDynamicCoins] = useState({});
  const [orders, setOrders] = useState([]);
  const [ordersLoaded, setOrdersLoaded] = useState(false);
  const [benchmarkData, setBenchmarkData] = useState({});
  const [benchmarkLoaded, setBenchmarkLoaded] = useState(false);

  const chartStyle = {
    xs: { height: '300px' },
    sm: { height: '350px' },
    md: { height: '350px' },
    lg: { height: '420px' },
    xl: { height: '450px' },
    xxl: { height: '450px' }
  };

  const findFirstNonZero = () => {
    for (let key in buttonMap) {
      let timepoints = buttonMap[key];
      for (let wallet in allWallet) {
        let walletTimepoints = allWallet[wallet][timepoints];
        for (let timepoint in walletTimepoints) {
          if (walletTimepoints[timepoint][currencyMap[currency]] !== 0) {
            return key;
          }
        }
      }
    }
    return null;
  };

  function hasNonZeroValues(key) {
    let timepoints = buttonMap[key];
    for (let wallet in allWallet) {
      let walletTimepoints = allWallet[wallet][timepoints];
      for (let timepoint in walletTimepoints) {
        if (walletTimepoints[timepoint][currencyMap[currency]] !== 0) {
          return true;
        }
      }
    }
    return false;
  }

  const getPortfolioName = id => {
    const portfolio = portfolioList.find(bot => String(bot.id) === String(id));
    return portfolio ? portfolio.name : '';
  };

  const getBotBenchmarkData = async botID => {
    setOrdersLoaded(false);
    setBenchmarkLoaded(false);
    var params = { bot_id: botID };
    let benchmarkResp = await endpointInterface(
      lang,
      'backend',
      'walletBenchmark',
      'get',
      true,
      params,
      true
    );
    if (benchmarkResp.validResponse) {
      setOrders(benchmarkResp.data.transactions);
      setBenchmarkData(benchmarkResp.data.coin);
    }
    setOrdersLoaded(true);
    setBenchmarkLoaded(true);
  };

  const handleSelectWallet = botID => {
    setShowCheckpointData(false);
    if (!activeRadio) setActiveRadio('All');
    if (walletNames.includes(botID)) {
      setZoomActive(false);
      setShowBenchmarkChart(false);
    } else {
      getBotBenchmarkData(botID);
    }
    let tmpDisplayedWallet;
    // check if selected wallet has the previous timerange, if not use 'All' as default
    if (
      Object.keys(allWallet[botID][buttonMap[activeRadio || 'All']]).length > 0
    )
      tmpDisplayedWallet = orderObjByDateKey(
        allWallet[botID][buttonMap[activeRadio || 'All']]
      );
    else {
      tmpDisplayedWallet = orderObjByDateKey(
        allWallet[botID][buttonMap['All']]
      );
      setActiveRadio('All');
    }
    setSelectedBotID(botID);
    setDisplayedWallet(tmpDisplayedWallet);
    updateBotGain({ botID: botID, wallet: tmpDisplayedWallet });
  };

  const getColorAndCaret = num => {
    if (Number(num) < 0) return ['danger', 'caret-down'];
    if (Number(num) > 0) return ['success', 'caret-up'];
    return ['1100', 'minus'];
  };

  const handleSelectRadio = (radioName, disableZoom = true) => {
    setShowCheckpointData(false);
    setShowBenchmarkChart(false);
    setZoomActive(false);
    setActiveRadio(radioName);
    if (disableZoom) setZoomActive(false);
    let tmpDisplayedWallet = orderObjByDateKey(
      allWallet[selectedBotID][buttonMap[radioName]]
    );
    setDisplayedWallet(tmpDisplayedWallet);
    updateBotGain({ wallet: tmpDisplayedWallet, radioButton: radioName });
    // to reset the wizard in botDetails
    if (!multiBot && !simplifyChart)
      setTriggerLoadCheckpoint({
        ...triggerLoadCheckpoint,
        id: null,
        ts: null
      });
  };

  const updateBotGain = ({
    botID = selectedBotID,
    wallet = displayedWallet,
    radioButton = activeRadio,
    useWallet = false
  }) => {
    if (
      Object.keys(dynamicBotMap).length > 0 &&
      Object.keys(dynamicBotMap).includes(String(botID))
    ) {
      let localCoins = Object.keys(dynamicCoins).length
        ? dynamicCoins
        : coinValues.current;
      let localActualValue = useWallet
        ? wallet[wallet.length - 1][currencyMap[currency]]
        : calculateCoinsValue(dynamicBotMap[botID].wallet, localCoins);
      let startVale =
        radioButton !== 'All'
          ? wallet[0][currencyMap[currency]]
          : dynamicBotMap[botID].initialAmount;
      let showBotGain = startVale
        ? ((localActualValue - startVale) / startVale) * 100
        : 0;
      setSelectedBotGain(showBotGain.toFixed(2));
      setSelectedBotValue(Number(localActualValue).toFixed(2));
    }
  };

  const convertWalletToArray = wallet => {
    let arr = Object.keys(wallet).map(key => ({
      ts: key,
      [currencyMap[currency]]: wallet[key][currencyMap[currency]]
    }));

    arr.sort((a, b) => new Date(a.ts) - new Date(b.ts));
    return arr;
  };

  const getCheckpointData = async checkpointID => {
    let checkPointResp = await endpointInterfaceV2({
      internalEndpoint: 'walletSnapshot',
      httpMethod: 'get',
      usingCredentials: true,
      pathParams: {
        snapshot_id: checkpointID
      },
      getDataFromCache: true,
      cacheTime1: 60,
      cacheTime2: 60 * 24 * 7 // 7 days of cache
    });
    if (checkPointResp.validResponse) {
      let localWalletToDisplay = convertWalletToArray(checkPointResp.data);
      setDisplayedWallet(localWalletToDisplay);
      updateBotGain({ wallet: localWalletToDisplay, useWallet: true });
      return true;
    }
    return false;
  };

  const getBotDataById = id => {
    for (const key in botGeneralInfo) {
      if (botGeneralInfo[key].id === id) {
        return { type: key, name: botGeneralInfo[key].name };
      }
    }
    return 'noBot';
  };

  const handleTrackEvent = () => {
    trackEvent('Chart Benchmark Click', {
      bot_name: allWallet[selectedBotID].bot_details.name,
      bot_type: allWallet[selectedBotID].bot_details.type_name,
      benchmark: singleBotBenchmark
    });
  };

  const handleTrackEventSimply = () => {
    trackEvent('dashboard', {
      event: 'Click on chart'
    });
  };

  const handleClickZoom = () => {
    handleTrackEvent();
    handleSelectRadio('All', false);
    setZoomActive(!zoomActive);
    setShowBenchmarkChart(!showBenchmarkChart);
    setShowCheckpointData(false);
    // to reset the wizard in botDetails
    if (!multiBot && !simplifyChart)
      setTriggerLoadCheckpoint({
        ...triggerLoadCheckpoint,
        id: null,
        ts: null
      });
  };

  /**
   * Used to calculate the data to display when the user clicks the last checkpoint button.
   *
   * Find the first value in the data object that contains the given timestamp and
   * return a new object that contains only the keys from the given timestamp onwards.
   *
   *
   * @param {Object} buttonMap - An object that maps button labels to timepoints keys.
   * @param {Object} data - An object that contains timepoints data.
   * @param {string} timestampStr - The timestamp to search for, in ISO 8601 format.
   */
  const calcEndCheckpointData = timestampStr => {
    const data = allWallet[selectedBotID];
    const timestamp = new Date(timestampStr);
    // Find the first value in the data object that contains the given timestamp
    let foundValue = null;
    for (let key in buttonMap) {
      const timepointsKey = buttonMap[key];
      if (data[timepointsKey]) {
        const timepoints = Object.keys(data[timepointsKey]).sort();
        const start = new Date(timepoints[0]);
        const end = new Date(timepoints[timepoints.length - 1]);
        if (timestamp >= start && timestamp <= end) {
          foundValue = data[timepointsKey];
          break;
        }
      }
    }
    // If no value was found, return null
    if (!foundValue) {
      return null;
    }
    // Filter the found value to contain only the keys from the given timestamp onwards
    let filteredValue = Object.keys(foundValue)
      .sort()
      .filter(key => new Date(key) >= timestamp)
      .reduce((obj, key) => {
        obj[key] = foundValue[key];
        return obj;
      }, {});
    let localWalletToDisplay = convertWalletToArray(filteredValue);
    setDisplayedWallet(localWalletToDisplay);
    updateBotGain({ wallet: localWalletToDisplay, useWallet: true });
    setSelectedBotValue(
      localWalletToDisplay[localWalletToDisplay.length - 1][
        currencyMap[currency]
      ].toFixed(2)
    );
    return true;
  };

  const handleCheckpointData = async (id, ts) => {
    var check = false;
    if (id === 'start') handleSelectRadio('All');
    else if (id === 'end') check = calcEndCheckpointData(ts);
    else check = await getCheckpointData(id);

    if (check) {
      setActiveRadio(null);
      setShowCheckpointData(true);
    }
    // to prevent refresh the chart from the bot details wizard
    if (!multiBot && !simplifyChart)
      setTriggerLoadCheckpoint({ ...triggerLoadCheckpoint, id: id, ts: ts });
  };

  useEffect(async () => {
    try {
      if (parentLoaded && !zoomActive && !showCheckpointData) {
        let localWaleltID = String(singleBotID ? singleBotID : exchange);
        let localDisplyedWallet;
        let localActiveRadio =
          activeRadio === null
            ? Object.keys(buttonMap).includes(chartGranularity)
              ? chartGranularity
              : findFirstNonZero()
            : activeRadio
            ? activeRadio
            : findFirstNonZero();
        if (activeRadio !== localActiveRadio) setActiveRadio(localActiveRadio);
        if (displayedWallet.length === 0) {
          if (
            localActiveRadio &&
            Object.keys(allWallet[localWaleltID][buttonMap[localActiveRadio]])
              .length > 1
          ) {
            localDisplyedWallet = orderObjByDateKey(
              allWallet[localWaleltID][buttonMap[localActiveRadio]]
            );
          } else {
            localDisplyedWallet = orderObjByDateKey(
              allWallet[localWaleltID][buttonMap['All']]
            );
            setActiveRadio('All');
            localActiveRadio = 'All';
          }
        } else localDisplyedWallet = displayedWallet;
        setDisplayedWallet(localDisplyedWallet);
        updateBotGain({
          wallet: localDisplyedWallet,
          botID: localWaleltID
        });
        if (!loaded) {
          setShowWallet(Object.keys(allWallet).length > 0);
          let botList = [];
          let wallet = [];
          let tmpShowBots = false;
          Object.entries(allWallet).map(([id, data]) => {
            data['walletID'] = id;
            if (walletNames.includes(id)) {
              data['bot_details'] = {};
              data['bot_details']['name'] = 'WALLET';
              wallet.push(data);
            } else {
              botList.push(data);
              tmpShowBots = true;
            }
          });
          setSelectedBotID(localWaleltID);
          setShowBots(tmpShowBots);
          setLoaded(true);
        }
      }
    } catch (error) {
      console.error(error.message);
    }
  }, [parentLoaded]);

  useEffect(async () => {
    var intervalCoinsData = setInterval(async () => {
      if (coinValues.current) {
        setDynamicCoins(coinValues.current);
      }
    }, 1000);

    return () => {
      clearInterval(intervalCoinsData);
    };
  }, []);

  useEffect(() => {
    if (!multiBot && !simplifyChart && selectedBotID && singleBotBenchmark) {
      getBotBenchmarkData(selectedBotID);
    }
  }, [selectedBotID]);

  // TAPULLO BRUTTO: used only in the bot details wizard to refresh the chart when the user clicks on a checkpoint
  // se hai idee migliori per fare il refresh del grafico da dentro al wizard della bot details famme sape'
  useEffect(() => {
    if (triggerLoadCheckpoint?.counter && triggerLoadCheckpoint?.counter > 0) {
      handleCheckpointData(triggerLoadCheckpoint.id, triggerLoadCheckpoint.ts);
      if (zoomActive) {
        setZoomActive(false);
        setShowBenchmarkChart(false);
      }
    }
  }, [triggerLoadCheckpoint.counter]);

  const memoizedLinePaymentChart = useMemo(
    () => (
      <LinePaymentChart
        displayedWallet={displayedWallet}
        granularity={activeRadio}
        ref={chartRef}
        style={chartStyle}
        events={allWallet[selectedBotID]?.snapshots || []}
        handleCheckpointData={handleCheckpointData}
        setShowPercentage={setShowPercentage}
        simplifyChart={simplifyChart}
        bgStyle={portfolioBgStyle}
      />
    ),
    [displayedWallet, activeRadio, selectedBotID, chartRef]
  );

  const memoizedLinePaymentChartBenchmark = useMemo(
    () => (
      <LinePaymentChartBenchmark
        benchmarkName={singleBotBenchmark}
        orders={orders}
        zoomActive={zoomActive}
        benchmark={benchmarkData}
        botData={allWallet?.[selectedBotID]?.bot_details || {}}
        style={chartStyle}
        ref={chartBenchmarkRef}
      />
    ),
    [
      displayedWallet,
      activeRadio,
      orders,
      zoomActive,
      benchmarkData,
      selectedBotID,
      chartBenchmarkRef
    ]
  );

  return (
    <Card
      {...(simplifyChart && {
        as: Link,
        to: '/wallet',
        style: {
          textDecoration: 'none',
          color: 'inherit',
          backgroundImage: portfolioBgStyle
        },
        onClick: () => handleTrackEventSimply()
      })}
      className={classNames('rounded-3 overflow-hidden shadow-none', {
        ' h-100': h100,
        'd-none': parentLoaded && loaded && !showWallet,
        pointerCursor: simplifyChart
      })}
    >
      <Card.Body
        className={classNames('', {
          'bg-line-chart-gradient':
            simplifyChart && !portfolioBgStyle && parentLoaded
        })}
        as={Flex}
        justifyContent="between"
        direction="column"
      >
        {!parentLoaded || !loaded ? (
          <div style={{ paddingTop: '20%', paddingBottom: '20%' }}>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {simplifyChart ? (
              <div
                style={{
                  position: 'absolute',
                  top: '30px',
                  left: '30px',
                  zIndex: 1
                }}
              >
                <h4
                  className="mb-2"
                  style={{ color: 'white', textShadow: '0 0 8px #000000' }}
                >
                  {textLang.goto[lang]}
                  <FontAwesomeIcon
                    icon="chevron-right"
                    className="ms-2 ps-1"
                    size="sm"
                  />
                </h4>
              </div>
            ) : (
              <Row className="align-items-center g-0">
                <Col
                  className={'d-flex mb-1 justify-content-center'}
                  xs={12}
                  xl={6}
                >
                  {showWallet && showBots && multiBot ? (
                    <>
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="outline-secondary"
                          style={{
                            border: 'none',
                            fontSize: 'large',
                            color: 'white'
                          }}
                        >
                          {selectedBotID !== exchange &&
                            allWallet[selectedBotID]?.bot_details?.category !==
                              'portfolio' && (
                              <span className="pe-3">
                                {walletImgRender(
                                  dynamicBotMap[selectedBotID].assets,
                                  '',
                                  false
                                )}
                              </span>
                            )}
                          <b>
                            {allWallet[selectedBotID]?.bot_details?.category ===
                            'portfolio'
                              ? getPortfolioName(
                                  allWallet[selectedBotID]?.bot_details.type
                                )
                              : allWallet[selectedBotID]?.bot_details?.name ||
                                ''}
                          </b>
                          {' $' +
                            (selectedBotID !== exchange
                              ? selectedBotValue != null
                                ? selectedBotValue
                                : ''
                              : generalWallet != null && dynamicLoaded
                              ? generalWallet.toFixed(2)
                              : '')}
                          {selectedBotID !== exchange && (
                            <span
                              style={{
                                paddingLeft: '10px',
                                color: `var(--falcon-${
                                  getColorAndCaret(selectedBotGain)[0]
                                })`
                              }}
                            >
                              {!zoomActive && showPercentage && (
                                <>
                                  {Number(selectedBotGain) !== 0 && (
                                    <FontAwesomeIcon
                                      icon={
                                        getColorAndCaret(selectedBotGain)[1]
                                      }
                                      className="me-1"
                                    />
                                  )}
                                  {Math.abs(Number(selectedBotGain))}%
                                </>
                              )}
                            </span>
                          )}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <div
                            className="scrollbar py-1"
                            style={{
                              maxHeight:
                                window.innerWidth < 576 ? '15rem' : '22rem'
                            }}
                          >
                            <Dropdown.Item
                              onClick={() => handleSelectWallet(exchange)}
                              className={
                                selectedBotID === exchange ||
                                (walletNames.includes(exchange) &&
                                  selectedBotID === exchange)
                                  ? 'bg-primary'
                                  : '' + ' ' + walletNames.includes(exchange)
                                  ? 'mb-2'
                                  : 'mb-1'
                              }
                            >
                              <p className="mb-0 d-flex">
                                <b className="pe-1">
                                  {allWallet[exchange].bot_details?.name}
                                </b>
                                {' $' +
                                  (generalWallet != null && dynamicLoaded
                                    ? generalWallet.toFixed(2)
                                    : '')}
                              </p>
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            {Object.entries(dynamicBotMap).map(
                              ([id, data]) =>
                                id !== exchange &&
                                allWallet[id].bot_details?.category ===
                                  'portfolio' &&
                                Object.keys(allWallet[id]['all_timepoints'])
                                  .length > 0 && (
                                  <>
                                    <Dropdown.Item
                                      onClick={() => handleSelectWallet(id)}
                                      className={
                                        selectedBotID === id ||
                                        (walletNames.includes(id) &&
                                          selectedBotID === exchange)
                                          ? 'bg-primary'
                                          : '' + ' ' + walletNames.includes(id)
                                          ? 'mb-2'
                                          : 'mb-1'
                                      }
                                    >
                                      <small>{'AI Portfolio'}</small>
                                      <p className="mb-0 d-flex">
                                        <b className="pe-1">
                                          {getPortfolioName(
                                            allWallet[id].bot_details.type
                                          )}
                                        </b>
                                        {' $' +
                                          (dynamicBotMap[id]
                                            ? calculateCoinsValue(
                                                dynamicBotMap[id].wallet,
                                                dynamicCoins
                                              ).toFixed(2)
                                            : '')}
                                      </p>
                                      {walletImgRender(
                                        data.assets,
                                        'mt-2',
                                        true,
                                        's',
                                        null
                                      )}
                                    </Dropdown.Item>
                                    <Dropdown.Divider />
                                  </>
                                )
                            )}
                            {Object.entries(dynamicBotMap).map(
                              ([id, data]) =>
                                id !== exchange &&
                                allWallet[id].bot_details?.category === 'bot' &&
                                Object.keys(allWallet[id]['all_timepoints'])
                                  .length > 0 && (
                                  <>
                                    <Dropdown.Item
                                      onClick={() => handleSelectWallet(id)}
                                      className={
                                        selectedBotID === id ||
                                        (walletNames.includes(id) &&
                                          selectedBotID === exchange)
                                          ? 'bg-primary'
                                          : '' + ' ' + walletNames.includes(id)
                                          ? 'mb-2'
                                          : 'mb-1'
                                      }
                                    >
                                      <p className="mb-0 d-flex">
                                        {walletImgRender(
                                          data.assets,
                                          'justify-content-center pe-2'
                                        )}
                                        <b className="pe-1">
                                          {allWallet[id]?.bot_details?.name ||
                                            ''}
                                        </b>
                                        {' $' +
                                          (dynamicBotMap[id]
                                            ? calculateCoinsValue(
                                                dynamicBotMap[id].wallet,
                                                dynamicCoins
                                              ).toFixed(2)
                                            : '')}
                                      </p>
                                      <small>
                                        {allWallet[id].bot_details
                                          ? getBotDataById(
                                              allWallet[id]?.bot_details.type
                                            ).name || ''
                                          : ''}
                                      </small>
                                    </Dropdown.Item>
                                  </>
                                )
                            )}
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  ) : (
                    <h5 className="mb-2">
                      {singleBotName +
                        ' $' +
                        (singleBotName !== exchange
                          ? selectedBotValue != null
                            ? selectedBotValue
                            : ''
                          : generalWallet != null && dynamicLoaded
                          ? generalWallet
                          : '')}
                      {selectedBotID !== exchange && (
                        <>
                          <span
                            style={{
                              paddingLeft: '10px',
                              color: `var(--falcon-${
                                getColorAndCaret(selectedBotGain)[0]
                              })`
                            }}
                          >
                            {!zoomActive && showPercentage && (
                              <>
                                {Number(selectedBotGain) !== 0 && (
                                  <FontAwesomeIcon
                                    icon={getColorAndCaret(selectedBotGain)[1]}
                                    className="me-1"
                                  />
                                )}
                                {Math.abs(Number(selectedBotGain))}%
                              </>
                            )}
                          </span>
                        </>
                      )}
                    </h5>
                  )}
                </Col>
                <Col
                  xs={12}
                  xl={6}
                  className={
                    'd-flex d-sm-flex justify-content-center justify-content-xl-end'
                  }
                >
                  {showWallet && (
                    <>
                      <ButtonGroup>
                        {singleBotID && singleBotBenchmark && (
                          <ToggleButton
                            size="sm"
                            key={'zoom'}
                            id={`radio-zoom`}
                            type="radio"
                            variant={
                              zoomActive ? 'falcon-primary' : 'falcon-default'
                            }
                            checked={zoomActive}
                            name={'zoom'}
                            style={{ marginRight: '10px' }}
                            onClick={() => handleClickZoom()}
                          >
                            <FontAwesomeIcon
                              icon="arrow-up"
                              size="xs"
                              className="text-success"
                            />
                            <FontAwesomeIcon
                              icon="arrow-down"
                              size="xs"
                              className="text-danger mt-1"
                            />
                          </ToggleButton>
                        )}
                        {Object.entries(buttonMap).map(([name, tag]) => (
                          <ToggleButton
                            size="sm"
                            key={name}
                            id={`radio-${name}`}
                            type="radio"
                            variant={
                              activeRadio === name && !zoomActive
                                ? 'falcon-primary'
                                : 'falcon-default'
                            }
                            disabled={
                              Object.keys(allWallet[selectedBotID][tag])
                                .length === 0 || !hasNonZeroValues(name)
                            }
                            name={name}
                            checked={
                              activeRadio === name &&
                              !zoomActive &&
                              !showCheckpointData
                            }
                            onChange={() => handleSelectRadio(name)}
                          >
                            {name}
                          </ToggleButton>
                        ))}
                      </ButtonGroup>
                    </>
                  )}
                </Col>
              </Row>
            )}
            {loaded &&
              showWallet &&
              (!showBenchmarkChart ? (
                memoizedLinePaymentChart
              ) : benchmarkLoaded && ordersLoaded ? (
                memoizedLinePaymentChartBenchmark
              ) : (
                <Flex
                  style={{ padding: '30%' }}
                  className="justify-content-center"
                >
                  <Spinner />
                </Flex>
              ))}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

LinePayment.propTypes = {
  allWallet: PropTypes.object.isRequired,
  dynamicBotMap: PropTypes.object.isRequired,
  generalWallet: PropTypes.number,
  multiBot: PropTypes.bool,
  h100: PropTypes.bool,
  dynamicLoaded: PropTypes.bool,
  loaded: PropTypes.bool,
  setLoaded: PropTypes.func,
  singleBotID: PropTypes.string,
  singleBotName: PropTypes.string,
  singleBotBenchmark: PropTypes.string,
  setEvents: PropTypes.func,
  triggerLoadCheckpoint: PropTypes.object,
  setTriggerLoadCheckpoint: PropTypes.func,
  parentLoaded: PropTypes.bool,
  exchange: PropTypes.string,
  simplifyChart: PropTypes.bool,
  portfolioBgStyle: PropTypes.bool
};

export default LinePayment;
