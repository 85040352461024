import React, { useState, useContext, useEffect } from 'react';
import { Col, Row, Card, Spinner } from 'react-bootstrap';
import { VirtualMode as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import hodlieCard from 'assets/img/hodlie/wallet/hodlie.png';
import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';
import { blueColor } from 'staticData/common';
import { coinsData } from 'services/coins/common';
import InfoPopover from 'components/utilities/InfoPopover';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import CoinsCake from './CoinsCake';
import 'assets/scss/custom/tables.scss';
import 'assets/scss/custom/walletPage.scss';
import { breakpoints } from 'helpers/utils';
import virtualCoinImg from 'assets/img/hodlie/coin/virtual-coin.png';
import { canShowRoute, teamLevel } from 'staticData/common';
import classNames from 'classnames';

const WalletHeader = () => {
  const {
    config: { lang },
    userInfos
  } = useContext(AppContext);
  const [coinInfos, setCoinInfos] = useState({});
  const [loadedCoinInfos, setLoadedCoinInfos] = useState(false);
  const [allocations, setAllocations] = useState({});
  const [virtualTotal, setVirtualTotal] = useState(0);
  const [loaded, setLoaded] = useState(false);
  const [wallet, setWallet] = useState({});
  const walletName = 'Virtual';
  const dataPerTablePage = 5;

  const coinOrderedInfos = [
    { code: 'BTC', name: 'Bitcoin' },
    { code: 'ETH', name: 'Ethereum' },
    { code: 'BNB', name: 'Binance Coin' },
    { code: 'SOL', name: 'Solana' },
    { code: 'ADA', name: 'Cardano' },
    { code: 'DOT', name: 'Polkadot' },
    { code: 'XRP', name: 'Ripple' },
    { code: 'USDT', name: 'USD Tether' },
    { code: 'FDUSD', name: 'First Digital USD' },
    { code: 'TUSD', name: 'TrueUSD' },
    { code: 'hodlie', name: 'Disponibile' }
  ];

  const defaultWallet = [
    {
      code: 'BTC',
      name: 'Bitcoin',
      quantity: 0,
      equivalent: 0,
      remainingInExchange: 0,
      equivalentRemainingInExchange: 0,
      hodliePercentage: 0
    },
    {
      code: 'ETH',
      name: 'Ethereum',
      quantity: 0,
      equivalent: 0,
      remainingInExchange: 0,
      equivalentRemainingInExchange: 0,
      hodliePercentage: 0
    },
    {
      code: 'SOL',
      name: 'Solana',
      quantity: 0,
      equivalent: 0,
      remainingInExchange: 0,
      equivalentRemainingInExchange: 0,
      hodliePercentage: 0
    },
    {
      code: 'USDT',
      name: 'USD Tether',
      quantity: 0,
      equivalent: 0,
      remainingInExchange: 0,
      equivalentRemainingInExchange: 0,
      hodliePercentage: 0
    }
  ];

  const cointTableData = input => {
    // Create a mapping object for coin names
    const coinNameMap = coinOrderedInfos.reduce((acc, coin) => {
      acc[coin.code] = coin.name;
      return acc;
    }, {});

    let output = [];

    // Calculate the total equivalent for percentage calculation
    let totalEquivalent = 0;

    // First pass: calculate the total equivalent
    for (let coin in input) {
      totalEquivalent += input[coin].amount_allocated * input[coin].price;
    }

    // Iterate over each coin
    for (let coin in input) {
      let quantity = Math.max(input[coin].amount_allocated, 0);
      let remainingInExchange = Math.max(
        input[coin].amount_exchange - quantity,
        0
      );
      let equivalent = Math.max(quantity * input[coin].price, 0);
      let equivalentRemainingInExchange = Math.max(
        remainingInExchange * input[coin].price,
        0
      );
      let hodliePercentage = Math.max((equivalent / totalEquivalent) * 100, 0);

      // Add the coin to the output array
      output.push({
        code: coin,
        name: coinNameMap[coin] || coin,
        quantity,
        equivalent,
        remainingInExchange,
        equivalentRemainingInExchange,
        hodliePercentage
      });
    }
    return output;
  };

  const columns = [
    {
      accessor: 'name',
      Header: textLang.name[lang],
      headerProps: {
        className: 'text-900',
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      cellProps: {
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      Cell: rowData => {
        const { name, code } = rowData.row.original;
        return (
          <Flex
            className={classNames('align-items-baseline', {
              'mt-3': code === 'hodlie'
            })}
          >
            <h6 className="mb-0">
              {(code === 'hodlie' || coinsData[code]) && (
                <img
                  src={code === 'hodlie' ? virtualCoinImg : coinsData[code].img}
                  alt={name}
                  width="20px"
                  className="me-2"
                />
              )}
              {window.innerWidth < breakpoints['sm'] && code !== 'hodlie'
                ? code
                : name}
            </h6>
          </Flex>
        );
      }
    },
    {
      accessor: 'equivalent',
      Header:
        window.innerWidth < breakpoints['sm'] ? '$' : textLang.equivalent[lang],
      headerProps: {
        className: 'text-900',
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      cellProps: {
        style:
          window.innerWidth < breakpoints['sm']
            ? { padding: '.75rem .25rem' }
            : {}
      },
      Cell: rowData => {
        const { equivalent, hodliePercentage, code } = rowData.row.original;
        return (
          <Flex
            className={classNames('align-items-baseline', {
              'mt-3': code === 'hodlie'
            })}
          >
            {equivalent >= 0 && (
              <h6 className="mb-0">${equivalent.toFixed(2)}</h6>
            )}
            {code !== 'hodlie' && (
              <p className="mb-0 ps-1 fs--2">
                ({hodliePercentage ? hodliePercentage.toFixed(2) : 0}%)
              </p>
            )}
          </Flex>
        );
      }
    }
  ];

  const calculateAllocations = input => {
    let result = {
      totalAllocated: 0,
      totalAvailable: 0
    };

    let allocated = 0;
    let available = 0;

    for (let coin of input) {
      allocated += coin.equivalent;
      available += coin.equivalentRemainingInExchange;
    }

    result = { allocated, available };
    setAllocations(result);
    return result;
  };

  const walletMapData = {
    Virtual: {
      img: hodlieCard,
      guideLink: '#',
      available: true,
      userCanContact: false
    }
  };

  const getWalletAvailability = async () => {
    var params = {
      virtual: true
    };
    let availabilityResponse = await endpointInterface(
      lang,
      'backend',
      'walletAvailability_binance',
      'get',
      true,
      params,
      true,
      true,
      1,
      1
    );
    if (!availabilityResponse.validResponse) {
      toast.error(availabilityResponse.responseMessage, {
        closeButton: false
      });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
    } else {
      let localCointTableData = cointTableData(availabilityResponse.data);
      if (
        !localCointTableData.length ||
        (!canShowRoute('virtual-page', userInfos) &&
          userInfos?.level < teamLevel)
      )
        localCointTableData = defaultWallet;
      setCoinInfos(localCointTableData);
      calculateAllocations(localCointTableData);
      setLoadedCoinInfos(true);
    }
  };

  /**
   * Get only virtual wallet (TODO: use a single endpoint to get real and virtual)
   * @returns
   */
  const getVirtualWallet = async () => {
    var params = {
      exchange: 'virtual',
      coin: ''
    };
    let walletAmountResponse = await endpointInterface(
      lang,
      'backend',
      'getVirtualWallet',
      'get',
      true,
      params,
      true,
      true,
      1,
      1
    );
    if (!walletAmountResponse.validResponse) {
      toast.error(walletAmountResponse.responseMessage, { closeButton: false });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
      return { allocated: 0, amount: 0 };
    } else {
      let virtualWallet = walletAmountResponse.data;
      return {
        allocated: virtualWallet.allocatedWallet.toFixed(2),
        amount: (
          virtualWallet.availableWallet + virtualWallet.allocatedWallet
        ).toFixed(2)
      };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!loaded) {
        try {
          getWalletAvailability();
          let tmpVirtualExchange = await getVirtualWallet();
          setVirtualTotal(tmpVirtualExchange.amount);
          setWallet({
            [walletName]: {
              allocatedAmount: tmpVirtualExchange.allocated,
              amount: tmpVirtualExchange.amount
            }
          });
          setLoaded(true);
        } catch (error) {
          console.error(error.message);
        }
      }
    };

    fetchData();
  }, []);

  return (
    <Row key={walletName}>
      <Card
        style={{
          backgroundColor: 'var(--falcon-body-bg)',
          boxShadow: 'none'
        }}
      >
        <Card.Body>
          <Row className="align-items-center">
            <Col sm={4}>
              <Card.Img
                style={{ borderRadius: '19%' }}
                src={walletMapData[walletName].img}
              />
              {loaded && (
                <Row className="py-3">
                  <Flex className="mb-1">
                    <h6>
                      <FontAwesomeIcon icon="wallet" className="me-2" />
                      {textLang.total[lang]}:
                      {!wallet.error && (
                        <span className="ms-2 fs-1 text-700">
                          $
                          {!canShowRoute('virtual-page', userInfos) &&
                          userInfos?.level < teamLevel
                            ? '0.00'
                            : Number(virtualTotal).toFixed(2)}
                        </span>
                      )}
                    </h6>
                    {!wallet.error && (
                      <InfoPopover
                        Text={
                          textLang.onExchangePre[lang] +
                          walletName +
                          textLang.onExchangePost[lang]
                        }
                      ></InfoPopover>
                    )}
                  </Flex>
                  <Flex>
                    <h6 style={{ color: blueColor }}>
                      <FontAwesomeIcon icon="robot" className="me-2" />
                      {textLang.onHodlie[lang]}:
                      {!wallet.error && allocations?.allocated != null && (
                        <span className="ms-2 fs-1 text-700">
                          ${Number(allocations.allocated).toFixed(2)}
                        </span>
                      )}
                    </h6>
                    {!wallet.error && (
                      <InfoPopover Text={textLang.capValue[lang]}></InfoPopover>
                    )}
                  </Flex>
                </Row>
              )}
            </Col>
            <Col sm={5}>
              {!loadedCoinInfos ? (
                <Flex className="justify-content-center">
                  <Spinner />
                </Flex>
              ) : (
                coinInfos &&
                Object.keys(coinInfos).length > 0 && (
                  <Row className="pt-3">
                    <AdvanceTableWrapper
                      columns={columns.filter(
                        column =>
                          ![
                            'remainingInExchange',
                            'equivalentRemainingInExchange'
                          ].includes(column.accessor)
                      )}
                      data={
                        (coinInfos || []).filter(row => row.quantity !== 0)
                          .length > 0
                          ? [
                              ...(coinInfos || [])
                                .filter(row => row.quantity !== 0)
                                .sort((a, b) => b.equivalent - a.equivalent),
                              {
                                code: 'hodlie',
                                name: textLang.avail[lang],
                                equivalent: virtualTotal - allocations.allocated
                              }
                            ]
                          : coinInfos
                      }
                      sortable
                      pagination
                      perPage={dataPerTablePage}
                    >
                      <AdvanceTable
                        table
                        headerClassName="bg-40 text-nowrap align-middle d-none extra-class"
                        rowClassName="align-middle white-space-nowrap border-bottom-table"
                        tableProps={{
                          bordered: true,
                          striped: true,
                          className: 'fs-10 mb-0 overflow-hidden'
                        }}
                      />
                      <div className="mt-3">
                        {coinInfos.filter(row => row.quantity !== 0).length >
                          dataPerTablePage && (
                          <AdvanceTableFooter
                            rowCount={
                              (coinInfos || []).filter(
                                row => row.quantity !== 0
                              ).length
                            }
                            table
                            rowInfo
                            navButtons
                            rowsPerPageSelection
                          />
                        )}
                      </div>
                    </AdvanceTableWrapper>
                  </Row>
                )
              )}
            </Col>
            <Col sm={3}>
              {!loadedCoinInfos ? (
                <Flex className="justify-content-center">
                  <Spinner />
                </Flex>
              ) : (
                coinInfos &&
                Object.keys(coinInfos).length > 0 && (
                  <CoinsCake
                    coinChartData={
                      (coinInfos || []).filter(row => row.quantity !== 0)
                        .length > 0
                        ? (coinInfos || [])
                            .filter(row => row.quantity !== 0)
                            .sort((a, b) => b.equivalent - a.equivalent)
                        : coinInfos
                    }
                    holdChartData={{
                      allocated: Number(allocations.allocated).toFixed(2),
                      notAllocated: (
                        Number(virtualTotal) - Number(allocations.allocated)
                      ).toFixed(2)
                    }}
                  />
                )
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Row>
  );
};

export default WalletHeader;
