import React, { useState, useContext, useEffect, useRef } from 'react';
import classNames from 'classnames';
import Flex from 'components/common/Flex';
import PropTypes from 'prop-types';
import {
  Badge,
  Card,
  Col,
  Row,
  ListGroup,
  ListGroupItem,
  Button,
  Spinner,
  ButtonGroup
} from 'react-bootstrap';
import AppContext from 'context/Context';
import FormTitle from 'pages/common/FormTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconCardHeader from 'components/common/FalconCardHeader';
import SentimentChart from './SentimentChart';
import SimpleBarReact from 'simplebar-react';
import NewsModal from '../modals/NewsModal';
import { breakpoints } from 'helpers/utils';
import HalfDoughnutChart from './HalfDoughnutChart';
import InfoPopover from 'components/utilities/InfoPopover';
import SentimentCount from './SentimentCount';
import { endpointInterface } from 'services/endpointInterface/endpointInterface';
import SoftBadge from 'components/common/SoftBadge';
import { newsImgMap } from 'staticData/newsData';
import IconItem from 'components/common/icon/IconItem';
import { Sentiment as textLang, monthsList } from 'staticData/languages';
import { canShowRoute, teamLevel } from 'staticData/common';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { trackEvent } from 'services/externals/mixpanel';
import PricingModal from 'pages/Pricing/modals';

const getCentroIDString = (value, lang = 'en-EN') => {
  if (value >= 0.5)
    return [textLang.very[lang] + ' ' + textLang.positive[lang], 'success'];
  else if (value > 0.2 && value < 0.5)
    return [textLang.positive[lang], 'success'];
  else if (value >= -0.2 && value <= 0.2)
    return [textLang.neutral[lang], 'warning'];
  else if (value > -0.5 && value < -0.2)
    return [textLang.negative[lang], 'danger'];
  else if (value <= -0.5)
    return [textLang.very[lang] + ' ' + textLang.negative[lang], 'danger'];
  else return ['', 'secondary'];
};

const Project = React.forwardRef(
  (
    {
      project,
      setSelectedNews,
      index,
      isLast,
      selectedNews,
      newsRead,
      newToOriginalIndicesMap
    },
    ref
  ) => {
    const {
      config: { lang }
    } = useContext(AppContext);
    const {
      id,
      sentiment_score,
      // tickers,
      ts,
      title,
      impact_score,
      source_name,
      duplicates
    } = project;
    // const lastAccess = localStorage.getItem('lastAccess');
    // const isNew = new Date(ts) > new Date(lastAccess);
    const translatedTitle = typeof title !== 'object' ? title : title[lang];
    // const progress = sentiment_score * 100;
    // const color =
    //   progress === 0 ? 'primary' : progress < 0 ? 'danger' : 'success';
    const score = Number((impact_score * sentiment_score).toFixed(2));
    const date = new Date(ts);
    let months = monthsList.months[lang];
    const showDate =
      date.getDate() +
      ' ' +
      months[date.getMonth()] +
      ' ' +
      date.getHours() +
      ':' +
      (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes());

    const sourceHasImg = Object.keys(newsImgMap).includes(
      source_name.replace(/\s+/g, '').toLowerCase()
    );

    const fireEmoji = '\u{1F525}';
    const isHot = Number(duplicates) >= 5;

    const getTitlePreview = title => {
      let cut;
      if (window.innerWidth < breakpoints['sm']) cut = 90;
      else if (window.innerWidth < breakpoints['md']) cut = 35;
      else if (window.innerWidth < breakpoints['xxl']) cut = 45;
      else cut = 65;
      if (title.length < cut) return title;
      else return `${title.substring(0, cut)}...`;
    };
    return (
      <Row
        ref={ref}
        className={classNames(
          'pointerCursor position-relative align-items-center py-2 hover-bg-200',
          {
            'border-bottom border-200 ': !isLast,
            'bg-300 ':
              selectedNews === index && window.innerWidth > breakpoints['lg']
          }
        )}
        style={
          newsRead.includes(id) && selectedNews !== index
            ? { opacity: '.6' }
            : {}
        }
        onClick={() => setSelectedNews(newToOriginalIndicesMap[index])}
      >
        <Col xs={12} sm={9} className="py-1">
          <Flex className="align-items-center">
            <div className=" me-3" style={{ position: 'relative' }}>
              {sourceHasImg ? (
                <img
                  src={
                    newsImgMap[source_name.replace(/\s+/g, '').toLowerCase()]
                  }
                  alt={source_name}
                  width="30"
                  height="30"
                  className={`rounded-circle p-1`}
                  style={{
                    border: '1px solid',
                    borderColor:
                      sentiment_score <= -0.3
                        ? 'var(--falcon-danger)'
                        : sentiment_score <= 0.3
                        ? '#8B8E14'
                        : 'var(--falcon-success)'
                  }}
                />
              ) : (
                <IconItem
                  tag="div"
                  icon={'newspaper'}
                  size="sm"
                  iconClass="fs--2"
                  className="shadow-none"
                  style={{
                    border: '1px solid',
                    borderColor:
                      sentiment_score < 0
                        ? 'var(--falcon-danger)'
                        : 'var(--falcon-success)'
                  }}
                />
              )}
              {isHot && (
                <span
                  style={{ position: 'absolute', bottom: '-5px', right: 0 }}
                >
                  {fireEmoji}
                </span>
              )}
            </div>
            <h6 className="mb-0">
              {translatedTitle.length > 20
                ? getTitlePreview(translatedTitle)
                : translatedTitle}
              {/* {isNew && (
                  <Badge
                    pill
                    bg="200"
                    className="ms-2 text-success d-none d-sm-block"
                  >
                    {'new'}
                  </Badge>
                )} */}
              <div>
                <small className="text-800 d-sm-none">
                  <span className="me-2">{showDate}</span>|
                  <span className="ms-2">Score: {score}</span>
                </small>
                <small className="text-800 d-none d-sm-block">
                  {source_name}
                </small>
              </div>
            </h6>
          </Flex>
        </Col>
        <Col sm={3} className="d-none d-sm-block">
          <Row className="justify-content-center align-items-center">
            <Col xs={8} sm={8} className="px-0 d-flex justify-content-center">
              <div className="fs--1 fw-semi-bold">{showDate}</div>
            </Col>
            <Col sm={4} className={`pe-x1 d-flex justify-content-center`}>
              {score}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
);

Project.propTypes = {
  project: PropTypes.object,
  isLast: PropTypes.bool,
  setSelectedNews: PropTypes.func,
  index: PropTypes.number,
  selectedNews: PropTypes.number,
  newsRead: PropTypes.array,
  newToOriginalIndicesMap: PropTypes.func
};

const SingleNewsCard = ({ data, isModal = false }) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const date = new Date(data.ts);
  const formattedDate = date.toLocaleString(lang).replace(',', '').slice(0, -3);
  const sourceHasImg = Object.keys(newsImgMap).includes(
    data.source_name.replace(/\s+/g, '').toLowerCase()
  );
  const fireEmoji = '\u{1F525}';
  const isHot = Number(data.duplicates) >= 5;
  return (
    <>
      {data.image_url && (
        <Row className="justify-content-center">
          <Col xs={12} style={{ position: 'relative' }}>
            <Card.Img
              src={data.image_url}
              style={{ opacity: '.4' }}
              variant="top"
            />
            <div
              className="ms-3 mb-3"
              style={{
                position: 'absolute',
                bottom: 0,
                left: '1rem',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {sourceHasImg ? (
                <img
                  src={
                    newsImgMap[
                      data.source_name.replace(/\s+/g, '').toLowerCase()
                    ]
                  }
                  width="30"
                  height="30"
                  alt="Author logo"
                />
              ) : (
                <FontAwesomeIcon icon="newspaper" />
              )}
              <p
                className="ms-2 mb-0"
                style={{
                  color: 'white',
                  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)'
                }}
              >
                {data.source_name}
              </p>
            </div>
          </Col>
        </Row>
      )}
      <Card.Title className="pt-4">
        {typeof data.title !== 'object' ? data.title : data.title[lang]}
      </Card.Title>
      <small>{formattedDate}</small>
      {isHot && (
        <small>
          <Badge pill bg="200" className="ms-2 text-warning">
            {fireEmoji + textLang.multipleSources[lang]}
          </Badge>
        </small>
      )}
      <Card.Body className="p-0">
        <SimpleBarReact
          style={{
            maxHeight: window.innerWidth > breakpoints['sm'] ? '15rem' : '12rem'
          }}
        >
          <Card.Text>
            {typeof data.content !== 'object'
              ? data.content
              : data.content[lang]}
            {lang === 'it-IT' && (
              <>
                <br />
                <small>Testo tradotto automaticamente</small>
              </>
            )}
          </Card.Text>
        </SimpleBarReact>
      </Card.Body>
      <div
        className={isModal ? 'pe-4' : 'position-absolute bottom-0 pe-4'}
        style={{ width: '-webkit-fill-available' }}
      >
        <ListGroup className="list-group-flush">
          <ListGroupItem className="d-flex">
            score:{' '}
            {Number((data.impact_score * data.sentiment_score).toFixed(2))}
            <span>
              <InfoPopover Text={textLang.scoreExplain[lang]} />
            </span>
          </ListGroupItem>
          {
            /*data.tickers.length > 0 || */ data.topics.length > 0 && (
              <ListGroupItem>
                {data.tickers.map((ticker, index) => (
                  <Badge
                    key={index}
                    pill
                    bg="200"
                    className="me-2 text-primary"
                  >
                    {ticker}
                  </Badge>
                ))}
                {/* {data.topics.map((topic, index) => (
                <Badge key={index} pill bg="200" className="me-2 text-primary">
                  {topic}
                </Badge>
              ))} */}
              </ListGroupItem>
            )
          }
          {!isModal && (
            <Card.Footer
              className="justify-content-center"
              style={{ display: 'contents' }}
            >
              {/* <Card.Link href={data.source_url} target="__blank">
                <FontAwesomeIcon icon="external-link-alt" className="me-1" />
                {textLang.source[lang]}
              </Card.Link> */}
              <Button
                // as={Link}
                color="primary"
                size="sm"
                href={data.source_url}
                target="__blank"
                className="my-3"
              >
                <FontAwesomeIcon
                  icon="external-link-alt"
                  transform="shrink-4 down-1"
                  className="me-1"
                />
                {textLang.sourceBig[lang]}
              </Button>
            </Card.Footer>
          )}
        </ListGroup>
      </div>
    </>
  );
};

SingleNewsCard.propTypes = {
  data: PropTypes.object,
  isModal: PropTypes.bool,
  checkDays: PropTypes.number
};

const Sentiment = () => {
  const {
    config: { lang, newsRead },
    setConfig,
    userInfos
  } = useContext(AppContext);
  // can get the chart granularity from query parameter
  const queryParameters = new URLSearchParams(window.location.search);
  const urlNewsID = queryParameters.get('id');
  const [loaded, setLoaded] = useState(false);
  const [news, setNews] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showUpToPro, setShowUpToPro] = useState(false);
  const [sentimentImpactArray, setSentimentImpactArray] = useState([]);
  const [titleArray, setTitleArray] = useState([]);
  const [selectedNews, setSelectedNews] = useState(0);
  const [cryptoFearIndex, setCryptoFearIndex] = useState(0);
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [sentimentCount, setSentimentCount] = useState({});
  const [checkDays, setCheckDays] = useState(1);
  const [centroid, setCentroid] = useState([0, 0]);
  const newsReadRef = useRef(newsRead); // Create a ref to hold the current value of newsRead
  const [newsRefs, setNewsRef] = useState([]); // ref for news in list
  const [scoreOrder, setScoreOrder] = useState(undefined);
  const [dateOrder, setDateOrder] = useState(undefined);
  const [orderedNews, setOrderedNews] = useState([]);
  const [originalToNewIndicesMap, setOriginalToNewIndicesMap] = useState({});
  const [newToOriginalIndicesMap, setNewToOriginalIndicesMap] = useState({});
  const scrollContainerRef = useRef(null); // ref for scroll container
  const routerHistory = useHistory();
  const [hasData, setHasData] = useState({
    1: true,
    7: true
  });
  const daysToCountNews = {
    1: 20,
    7: 40
  };
  const defaultData = {
    sentimentData: { 1: [], 7: [] },
    fearIndex: 0
  };

  const orderNews = (type, localNews = news[checkDays], changeOrder = true) => {
    let localSortedNews = [...localNews];
    let localOriginalToNewIndicesMap = {};
    let localNewToOriginalIndicesMap = {};

    const sortAndMapIndices = compareFunction => {
      localSortedNews.sort(compareFunction);
      localSortedNews.forEach((item, newIndex) => {
        let originalIndex = localNews.indexOf(item);
        localOriginalToNewIndicesMap[originalIndex] = newIndex;
        localNewToOriginalIndicesMap[newIndex] = originalIndex;
      });
    };
    if (changeOrder) {
      setDateOrder(undefined);
      setScoreOrder(undefined);
    }
    if (type === 'date') {
      if (dateOrder === 'desc') {
        if (changeOrder) {
          setDateOrder('asc');
          sortAndMapIndices((a, b) => new Date(a.ts) - new Date(b.ts));
        } else sortAndMapIndices((a, b) => new Date(b.ts) - new Date(a.ts));
      } else {
        if (changeOrder) {
          setDateOrder('desc');
          sortAndMapIndices((a, b) => new Date(b.ts) - new Date(a.ts));
        } else sortAndMapIndices((a, b) => new Date(a.ts) - new Date(b.ts));
      }
    } else if (type === 'score') {
      if (scoreOrder === 'desc') {
        if (changeOrder) {
          setScoreOrder('asc');
          sortAndMapIndices(
            (a, b) =>
              a.impact_score * a.sentiment_score -
              b.impact_score * b.sentiment_score
          );
        } else
          sortAndMapIndices(
            (a, b) =>
              b.impact_score * b.sentiment_score -
              a.impact_score * a.sentiment_score
          );
      } else {
        if (changeOrder) {
          setScoreOrder('desc');
          sortAndMapIndices(
            (a, b) =>
              b.impact_score * b.sentiment_score -
              a.impact_score * a.sentiment_score
          );
        } else
          sortAndMapIndices(
            (a, b) =>
              a.impact_score * a.sentiment_score -
              b.impact_score * b.sentiment_score
          );
      }
    }
    setOrderedNews(localSortedNews);
    setOriginalToNewIndicesMap(localOriginalToNewIndicesMap);
    setNewToOriginalIndicesMap(localNewToOriginalIndicesMap);
    setNewsRef(localSortedNews.map(() => React.createRef()));
  };

  const handleTrackEvent = news => {
    trackEvent('Sentiment News Click', {
      id: news.id || null,
      title: news.content[lang] || null,
      sentiment: news.sentiment || null,
      impact: news.impact_score || null,
      source: news.source_name || null,
      coins: news.tickers || null
    });
  };

  const chooseNews = (localNews, scroll = false) => {
    handleTrackEvent(news[checkDays][localNews]);
    setSelectedNews(localNews);
    if (!newsReadRef.current.includes(news[checkDays][localNews].id)) {
      let localNewsRead = [...newsReadRef.current];
      localNewsRead.push(news[checkDays][localNews].id);
      newsReadRef.current = localNewsRead;
    }
    if (window.innerWidth < breakpoints['lg']) setShowModal(true);
    else
      setSelectedPoint({
        xy: sentimentImpactArray[localNews],
        index: localNews
      });
    if (scroll) {
      // get the top position of the element within the container
      let topPos =
        newsRefs[originalToNewIndicesMap[localNews]].current.offsetTop;
      // get the scrollable element
      let scrollElement = scrollContainerRef.current.getScrollElement();
      // scroll the container
      scrollElement.scrollTo({
        top: topPos,
        behavior: 'smooth'
      });
    }
  };

  const getRangeBasedOnValue = value => {
    if (value >= 0 && value <= 24) {
      return 'Extreme fear';
    } else if (value >= 25 && value <= 49) {
      return 'Fear';
    } else if (value >= 50 && value <= 74) {
      return 'Greed';
    } else if (value >= 75 && value <= 100) {
      return 'Extreme greed';
    }
  };

  const addNoise = (sentimentData, noisePercentage) => {
    const sentimentWithNoise = {};

    for (const key in sentimentData) {
      const newsArray = sentimentData[key];
      const newsWithNoise = newsArray.map(newsItem => {
        const id = newsItem.id;
        const fixedNoise = (id % 101) / 100;

        const noiseImpact = fixedNoise * noisePercentage;
        const noiseSentiment = fixedNoise * noisePercentage;

        const newImpactScore = Math.min(newsItem.impact_score + noiseImpact, 1);
        const newSentimentScore = Math.min(
          Math.max(newsItem.sentiment_score + noiseSentiment, -1),
          1
        );
        return {
          ...newsItem,
          impact_score: newImpactScore,
          sentiment_score: newSentimentScore
        };
      });

      sentimentWithNoise[key] = newsWithNoise;
    }

    return sentimentWithNoise;
  };

  // Example usage
  const noisePercentage = 0.3; // Adjust as needed

  const getSentimentInfo = async () => {
    var params = {
      news: JSON.stringify([
        { days: 1, count: daysToCountNews[1] },
        { days: 7, count: daysToCountNews[7] }
      ])
    };
    let sentimentResp = await endpointInterface(
      lang,
      'backend',
      'getSentiment',
      'get',
      true,
      params,
      true
    );
    if (sentimentResp.validResponse) {
      return {
        sentimentData: sentimentResp.data.news,
        fearIndex: sentimentResp.data.latest_crypto_fear_greed
      };
    } else {
      toast.error(sentimentResp.responseMessage, {
        closeButton: false
      });
      setTimeout(() => {
        toast.dismiss();
      }, 5000);
      return defaultData;
    }
  };

  const elaborateData = (sentimentData = news, days = checkDays) => {
    let localSentimentImpactArr = sentimentData[days].map(item => [
      item.sentiment_score,
      item.impact_score
    ]);
    setSentimentImpactArray(localSentimentImpactArr);
    setCentroid(
      localSentimentImpactArr
        .reduce(([xSum, ySum], [x, y]) => [xSum + x, ySum + y], [0, 0])
        .map(sum => sum / localSentimentImpactArr.length)
    );
    if (sentimentData[days].length > 0)
      setTitleArray(
        sentimentData[days].map(item =>
          typeof item.title !== 'object'
            ? item.title.substring(0, 15) + '...'
            : item.title[lang].substring(0, 15) + '...'
        )
      );
    setSelectedPoint(
      window.innerWidth > breakpoints['lg']
        ? { xy: localSentimentImpactArr[0], index: 0 }
        : null
    );
    let localSentimentCount = sentimentData[days].reduce(
      (acc, curr) => {
        if (curr.sentiment_score > 0) {
          acc.positive.number++;
        } else if (curr.sentiment_score < 0) {
          acc.negative.number++;
        }
        return acc;
      },
      {
        positive: { number: 0, percentage: 0 },
        negative: { number: 0, percentage: 0 }
      }
    );
    let totalNoNeutral =
      localSentimentCount.positive.number + localSentimentCount.negative.number;
    localSentimentCount.positive.percentage =
      (localSentimentCount.positive.number / totalNoNeutral) * 100;
    localSentimentCount.negative.percentage =
      (localSentimentCount.negative.number / totalNoNeutral) * 100;
    setSentimentCount(localSentimentCount);
  };

  const changeCheck = days => {
    setCheckDays(days);
    let actualOrder = dateOrder ? 'date' : scoreOrder ? 'score' : 'date';
    orderNews(actualOrder, news[days], false);
    elaborateData(news, days);
    setSelectedNews(0);
  };

  const handleHidePricingModal = show => {
    setShowUpToPro(show);
    if (!show) routerHistory.push('/');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setShowUpToPro(
          !canShowRoute('sentiment', userInfos) && userInfos?.level < teamLevel
        );
        let { sentimentData, fearIndex } =
          canShowRoute('sentiment', userInfos) || userInfos?.level >= teamLevel
            ? await getSentimentInfo()
            : defaultData;
        let localHasData = Object.fromEntries(
          Object.entries(hasData).map(([key]) => [
            key,
            sentimentData[key] && sentimentData[key].length > 0
          ])
        );
        let firstTruePeriod = Object.keys(localHasData).find(
          key => localHasData[key] === true
        );
        if (firstTruePeriod && Object.keys(sentimentData).length !== 0) {
          sentimentData = addNoise(sentimentData, noisePercentage);
          setNews(sentimentData);
          setCryptoFearIndex(fearIndex);
          orderNews('date', sentimentData[firstTruePeriod]);
          elaborateData(sentimentData, firstTruePeriod);
        }
        setHasData(localHasData);
        setCheckDays(Number(firstTruePeriod));
        setLoaded(true);
      } catch (error) {
        console.error(error.message);
      }
    };
    if (!loaded) fetchData();
    // if there is an id in url show the correct one the first time
    else if (urlNewsID) {
      const urlNewsIndex = news[checkDays].findIndex(
        obj => obj.id === Number(urlNewsID)
      );
      chooseNews(urlNewsIndex, true);
    }
  }, [loaded]);

  useEffect(() => {
    return () => {
      // if (!lastAccessRef.current) {
      //   lastAccessRef.current = true;
      //   localStorage.setItem('lastAccess', new Date().toString());
      // }
      if (newsReadRef.current !== newsRead) {
        // Only call setConfig if newsRead has changed
        setConfig('newsRead', newsReadRef.current);
      }
    };
  }, []);

  return (
    <>
      <PricingModal
        showModal={showUpToPro}
        setShowModal={handleHidePricingModal}
        whatToDo={userInfos?.pro_tier === 0 ? 'newSub' : 'upgrade'}
        newProTier={0}
        type="sentiment"
      />
      {loaded && hasData[checkDays] && news[checkDays].length > 0 && (
        <NewsModal
          showModal={showModal}
          setShowModal={setShowModal}
          body={
            <SingleNewsCard
              data={news[checkDays][selectedNews]}
              isModal={true}
              checkDays={checkDays}
            />
          }
          newsLink={news[checkDays][selectedNews].source_url}
        />
      )}
      <Row md={12}>
        <FormTitle
          icon="newspaper"
          title="Sentiment"
          subtitle={textLang.subtitle[lang]}
        />
      </Row>
      <Row className="">
        <Col lg={8}>
          <Row>
            <Col xs={12}>
              <Card className="px-0">
                <FalconCardHeader
                  title={
                    <Row className="position-relative align-items-center">
                      <Col
                        xs={12}
                        sm={9}
                        className="d-flex justify-content-between pe-sm-0"
                      >
                        <Flex
                          className="justify-content-between"
                          style={{
                            width:
                              window.innerWidth < breakpoints['sm']
                                ? '-webkit-fill-available'
                                : ''
                          }}
                        >
                          <span className="pt-2">News</span>
                          <ButtonGroup
                            size="sm"
                            className="ps-3 d-flex justify-content-end justify-content-sm-start"
                          >
                            <Button
                              variant={
                                checkDays === 1
                                  ? 'falcon-primary'
                                  : 'falcon-default'
                              }
                              onClick={() =>
                                checkDays === 1 ? null : changeCheck(1)
                              }
                              disabled={!loaded || !hasData[1]}
                            >
                              <small>24h</small>
                            </Button>
                            <Button
                              variant={
                                checkDays === 7
                                  ? 'falcon-primary'
                                  : 'falcon-default'
                              }
                              onClick={() =>
                                checkDays === 7 ? null : changeCheck(7)
                              }
                              disabled={!loaded || !hasData[7]}
                            >
                              <small>7d</small>
                            </Button>
                          </ButtonGroup>
                        </Flex>
                        {loaded && hasData[checkDays] && (
                          <p className="mb-2 pt-2 d-none d-sm-block">
                            {textLang.order[lang]}
                          </p>
                        )}
                      </Col>
                      <Col className="d-none d-md-block">
                        {loaded && hasData[checkDays] && (
                          <Row className="align-items-center justify-content-center">
                            <Col xs={6} sm={8} className="px-0">
                              <Flex
                                onClick={() => orderNews('date')}
                                style={{ cursor: 'pointer' }}
                                className="justify-content-center"
                              >
                                <p
                                  className="mb-0 me-2"
                                  style={
                                    dateOrder
                                      ? { textDecoration: 'underline' }
                                      : {}
                                  }
                                >
                                  {textLang.date[lang]}
                                </p>
                                {dateOrder && (
                                  <FontAwesomeIcon
                                    icon={`angle-${
                                      dateOrder === 'desc' ? 'down' : 'up'
                                    }`}
                                    size="xs"
                                    className="mt-1"
                                  />
                                )}
                              </Flex>
                            </Col>
                            <Col xs={4}>
                              <Flex
                                onClick={() => orderNews('score')}
                                style={{ cursor: 'pointer' }}
                                className="justify-content-center me-2"
                              >
                                <p
                                  className="mb-0 me-2"
                                  style={
                                    scoreOrder
                                      ? { textDecoration: 'underline' }
                                      : {}
                                  }
                                >
                                  score
                                </p>
                                {scoreOrder && (
                                  <FontAwesomeIcon
                                    icon={`angle-${
                                      scoreOrder === 'desc' ? 'down' : 'up'
                                    }`}
                                    size="xs"
                                    className="mt-1"
                                  />
                                )}
                              </Flex>
                            </Col>
                          </Row>
                        )}
                      </Col>
                    </Row>
                  }
                  light
                  titleTag="h6"
                />
                <SimpleBarReact
                  ref={scrollContainerRef}
                  style={
                    window.innerWidth < breakpoints['sm']
                      ? {
                          maxHeight: '16rem',
                          boxShadow: 'inset 0px -10px 20px 0px #0000006b'
                        }
                      : {
                          maxHeight: '22rem'
                        }
                  }
                >
                  <Card.Body className="py-0">
                    {!loaded ? (
                      <Flex
                        style={{ padding: '20% 5%' }}
                        className="justify-content-center"
                      >
                        <Spinner />
                      </Flex>
                    ) : hasData[checkDays] ? (
                      orderedNews.map((project, index) => (
                        <Project
                          ref={newsRefs[index]}
                          project={project}
                          setSelectedNews={chooseNews}
                          selectedNews={originalToNewIndicesMap[selectedNews]}
                          index={index}
                          isLast={index === news[checkDays].length - 1}
                          newsRead={newsReadRef.current}
                          newToOriginalIndicesMap={newToOriginalIndicesMap}
                          key={index}
                        />
                      ))
                    ) : (
                      <Flex
                        style={{ padding: '20% 5%' }}
                        className="justify-content-center"
                      >
                        {textLang.noData[lang]}
                      </Flex>
                    )}
                  </Card.Body>
                </SimpleBarReact>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3">
            {!loaded ? (
              <Flex
                style={{ padding: '10% 0' }}
                className="justify-content-center"
              >
                <Spinner />
              </Flex>
            ) : (
              <SentimentChart
                data={sentimentImpactArray}
                clickPoint={chooseNews}
                selectedPoint={selectedPoint}
                setSelectedPoint={setSelectedPoint}
                titleArray={titleArray}
                centroid={centroid}
                hasData={hasData[checkDays]}
              />
            )}
          </Row>
        </Col>
        <Col lg={4} className="d-none d-lg-block">
          <Card className="h-100">
            <Card.Body>
              {!loaded ? (
                <Flex
                  style={{ padding: '60% 0' }}
                  className="justify-content-center"
                >
                  <Spinner />
                </Flex>
              ) : !hasData[checkDays] ? (
                <Flex
                  style={{ padding: '60%' }}
                  className="justify-content-center"
                ></Flex>
              ) : selectedNews !== undefined &&
                news[checkDays][selectedNews] ? (
                <SingleNewsCard
                  data={news[checkDays][selectedNews]}
                  checkDays={checkDays}
                />
              ) : (
                'Seleziona una news per caricarne il contenuto'
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={4}>
          <Card className="h-md-100">
            <Card.Header as={Flex} className="pb-0">
              <h6 className="mb-0 mt-1">{textLang.generalScore[lang]}</h6>
              <InfoPopover Text={textLang.scoreInfo[lang]} />
            </Card.Header>
            <Card.Body as={loaded ? Flex : 'span'} className="mt-3">
              {!loaded ? (
                <Flex
                  style={{ padding: '5%' }}
                  className="justify-content-center"
                >
                  <Spinner />
                </Flex>
              ) : (
                <>
                  <div>
                    <h2 className="mb-1 text-700 fw-normal lh-1 fs-2">
                      {hasData[checkDays] && news[checkDays].length > 0
                        ? (centroid[0] * centroid[1]).toFixed(2)
                        : 0}
                    </h2>
                  </div>
                  <div>
                    <SoftBadge
                      pill
                      bg={
                        hasData[checkDays] && news[checkDays].length > 0
                          ? getCentroIDString(centroid[0] * centroid[1])[1]
                          : 'secondary'
                      }
                      className="ms-2"
                    >
                      {hasData[checkDays] && news[checkDays].length > 0
                        ? getCentroIDString(centroid[0] * centroid[1])[0]
                        : 'none'}
                    </SoftBadge>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col md={4} className="mt-3 mt-md-0">
          <SentimentCount sentiment={sentimentCount} loaded={loaded} />
        </Col>
        <Col md={4} className="mt-3 mt-md-0">
          <Card className="h-md-100">
            <Card.Header as={Flex} className="pb-0">
              <h6 className="mb-0 mt-1">{textLang.fearAndGreed[lang]}</h6>
              <InfoPopover Text={textLang.fearInfo[lang]} />
            </Card.Header>
            <Card.Body
              as={loaded ? Flex : 'span'}
              alignItems="end"
              justifyContent="between"
              className="mt-3"
            >
              {!loaded ? (
                <Flex
                  style={{ padding: '5%' }}
                  className="justify-content-center"
                >
                  <Spinner />
                </Flex>
              ) : (
                <>
                  <div>
                    <h2 className="mb-1 text-700 fw-normal lh-1 fs-2">
                      {hasData[checkDays] && news[checkDays].length > 0
                        ? cryptoFearIndex
                        : '-'}
                      %
                    </h2>
                  </div>
                  <div>
                    <div className="my-n5">
                      <HalfDoughnutChart
                        value={
                          hasData[checkDays] && news[checkDays].length > 0
                            ? cryptoFearIndex
                            : 0
                        }
                      />
                    </div>
                    <p className="mb-0 mt-4 text-center fs--1 text-dark">
                      {hasData[checkDays] && news[checkDays].length > 0
                        ? getRangeBasedOnValue(cryptoFearIndex)
                        : ''}
                    </p>
                  </div>
                </>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Sentiment;
