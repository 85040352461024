import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import {
  Col,
  Row,
  Modal,
  ButtonGroup,
  ToggleButton,
  Spinner
} from 'react-bootstrap';
import IconButton from 'components/common/IconButton';
import Flex from 'components/common/Flex';
import warningLight from '../../../assets/img/animated-icons/warning-light.json';
import Lottie from 'lottie-react';
import { DeleteBotModal as textLang } from 'staticData/languages';
import AppContext from 'context/Context';

const DeleteBotModal = ({
  deleteBotModalShow,
  setDeleteBotModalShow,
  confirmBotDeletion,
  bot,
  waitDelete = false
}) => {
  const {
    config: { lang }
  } = useContext(AppContext);
  const [radioSellAll, setRadioSellAll] = useState('hold');

  const canSell =
    bot?.status.toUpperCase() !== 'PAUSED' &&
    bot?.status.toUpperCase() !== 'ERROR' &&
    !bot?.virtual;
  const canConvert =
    bot?.type === 'takeProfitBot30' &&
    bot?.status === 'ERROR' &&
    bot?.exchange === 'binance' &&
    !bot?.virtual;

  const showCanConvert = false;

  return (
    <Modal
      show={deleteBotModalShow}
      onHide={() => setDeleteBotModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center">
          <Col lg={12} className="d-flex justify-content-center">
            <Lottie
              animationData={warningLight}
              loop={true}
              style={{ height: '120px', width: '120px' }}
            />
          </Col>
        </Row>
        <h4 className="text-center font-weight-bold">{textLang.title[lang]}</h4>
        <p className="text-center">
          <br /> {textLang.text[lang]}
          <b className="text-danger">{textLang.text1[lang]}</b>
          {textLang.text2[lang]}
          <br />
          <br />
          {/* Hide SELL ALL Option if Paused or in Error*/}
          {showCanConvert && (canSell || canConvert) && (
            <>
              {canConvert ? textLang.textConvert[lang] : textLang.text4[lang]}
              <br />
              {canConvert ? textLang.textConvert1[lang] : textLang.text5[lang]}
            </>
          )}
        </p>
        {/* Hide SELL ALL Option if Paused or in Error*/}
        {showCanConvert && (canSell || canConvert) && (
          <>
            <Row className="justify-content-center">
              <Col className="justify-content-center d-flex">
                <ButtonGroup>
                  <ToggleButton
                    id={`radio-0`}
                    key={'0'}
                    type="radio"
                    variant={'outline-primary'}
                    name="radio"
                    value={'hold'}
                    checked={radioSellAll === 'hold'}
                    onChange={e => setRadioSellAll(e.currentTarget.value)}
                  >
                    {canConvert ? textLang.holdCoin[lang] : textLang.hold[lang]}
                  </ToggleButton>
                  <ToggleButton
                    id={`radio-1`}
                    key={'1'}
                    type="radio"
                    variant={'outline-primary'}
                    name="radio"
                    value={'sell'}
                    checked={radioSellAll === 'sell'}
                    onChange={e => setRadioSellAll(e.currentTarget.value)}
                  >
                    {canConvert
                      ? textLang.convertCoin[lang]
                      : textLang.sell[lang]}
                  </ToggleButton>
                </ButtonGroup>
              </Col>
            </Row>
            <p className="text-center">{textLang.continue[lang]}</p>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Flex className="col-6 m-0" style={{ justifyContent: 'start' }}>
          <IconButton
            onClick={() => setDeleteBotModalShow(false)}
            variant="outline-secondary"
            className="me-2 mb-1 "
            icon="arrow-left"
            iconAlign="left"
            transform="shrink-3"
          >
            {textLang.goBack[lang]}
          </IconButton>
        </Flex>
        <Flex className="col-6 m-0" style={{ justifyContent: 'end' }}>
          <IconButton
            onClick={() =>
              confirmBotDeletion(radioSellAll === 'sell' ? true : false)
            }
            variant="outline-danger"
            className="me-2 mb-1 "
            icon={waitDelete ? '' : 'trash'}
            iconAlign="left"
            transform="shrink-3"
            disabled={waitDelete}
          >
            {waitDelete ? (
              <Flex>
                <Spinner
                  animation="border"
                  className="me-2 mt-1"
                  style={{ width: '1rem', height: '1rem' }}
                ></Spinner>
                {textLang.yesDel[lang]}
              </Flex>
            ) : (
              textLang.yesDel[lang]
            )}
          </IconButton>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
};

DeleteBotModal.propTypes = {
  deleteBotModalShow: PropTypes.bool.isRequired,
  setDeleteBotModalShow: PropTypes.func.isRequired,
  confirmBotDeletion: PropTypes.func.isRequired,
  bot: PropTypes.object.isRequired,
  waitDelete: PropTypes.bool
};

export default DeleteBotModal;
