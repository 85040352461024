import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { ConfirmMailForm as textLang } from 'staticData/languages';
import AppContext from 'context/Context';
import 'assets/scss/custom/addPassword.scss';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

const ConfirmMailForm = ({ wrongUC }) => {
  const {
    config: { lang }
  } = useContext(AppContext);

  return wrongUC != undefined ? (
    <>
      {wrongUC ? (
        <>
          <h3 className="text-center">{textLang.notFoundTitle[lang]}</h3>
          <p className="opacity-75 text-center">
            {textLang.notFoundText[lang]}
          </p>
          <div className="d-flex justify-content-center">
            <Link to="/" className="btn btn-primary" role="button">
              {'Home'}
            </Link>
          </div>
        </>
      ) : (
        <>
          <h3 className="text-center">{textLang.foundTitle[lang]}</h3>
          <p className="opacity-75 text-center">
            {textLang.foundText[lang]}
            <br />
            {textLang.foundText2[lang]}
          </p>
          <div className="d-flex justify-content-center">
            <Link to="/" className="btn btn-primary" role="button">
              Home
            </Link>
          </div>
        </>
      )}
    </>
  ) : (
    <Spinner animation="border" role="status"></Spinner>
  );
};

ConfirmMailForm.propTypes = {
  hasLabel: PropTypes.bool,
  userData: PropTypes.object,
  wrongUC: PropTypes.bool,
  uniqueCode: PropTypes.string,
  pathname: PropTypes.string
};

export default ConfirmMailForm;
